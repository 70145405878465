import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ConditionalRoute = ({ component: Component, condition, ...rest }) => (
  <Route
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...rest}
    render={(props) =>
      // eslint-disable-next-line react/jsx-props-no-spreading
      condition === true ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

export default ConditionalRoute;
