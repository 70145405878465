import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Mute } from '../../../assets/icons/mute.svg';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  buttonStyle: {
    cursor: 'pointer',
    //width: ,
    height: '100%'
    
  },
  buttonStyleMobile: {
    // cursor: 'pointer',
    height: '100%'
  },
});

const PlayAudioButton = ({ onClick, className }) => {
  const matches = useMediaQuery('(max-width:690px)');
  const { buttonStyle, buttonStyleMobile } = useStyles();
  return <Mute className={`${buttonStyle} ${className} ${matches?buttonStyleMobile:''}`} onClick={onClick} />;
};

export default PlayAudioButton;
