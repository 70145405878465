import {
  UI_SEARCH_SUGGESTION_REQUEST,
  SEARCH_SUGGESTION_REQUEST,
  STORE_SEARCH_TERM,
  STORE_TEMP_SEARCH_TERM,
  SEARCH_SUGGESTION_REQUESTED,
  SEARCH_SUGGESTION_SUCCEEDED,
  SEARCH_SUGGESTION_FAILED,
  STORE_SEARCH_SUGGESTION_RESULT,
  UI_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE_REQUEST,
  STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE,
  UI_SEARCH_REQUEST,
  SEARCH_REQUESTED,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED,
  STORE_TEXT_SEARCH_RESULT,
  STORE_VIDEO_SEARCH_RESULT,
  STORE_PHOTO_SEARCH_RESULT,
  UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST,
  STORE_CLEAR_SEARCH_SUGGESTIONS,
  UI_IMG_RECOGNITION_REQUEST,
  STORE_IMG_TAGS_TERM,
  STORE_SET_SLIDER_INDEX,
  UI_REQEST_RANDOM_WORDS
} from './actionTypes';

const storeSetRandomSearchResultsAction = (payload) => ({
  type: UI_REQEST_RANDOM_WORDS,
  payload,
});

const storeSetSliderIndexAction = (payload) => ({
  type: STORE_SET_SLIDER_INDEX,
  payload,
});

const uiSearchSuggestionRequestAction = (payload) => ({
  type: UI_SEARCH_SUGGESTION_REQUEST,
  payload,
});

const searchSuggestionRequest = (payload) => ({
  type: SEARCH_SUGGESTION_REQUEST,
  payload,
});

const storeSearchTermAction = (payload) => ({
  type: STORE_SEARCH_TERM,
  payload,
});

const storeTempSearchTermAction = (payload) => ({
  type: STORE_TEMP_SEARCH_TERM,
  payload,
});

const searchSuggestionRequestedAction = () => ({
  type: SEARCH_SUGGESTION_REQUESTED,
});

const searchSuggestionSucceeded = () => ({
  type: SEARCH_SUGGESTION_SUCCEEDED,
});

const searchSuggestionFailed = () => ({
  type: SEARCH_SUGGESTION_FAILED,
});

const storeSearchSuggestionResult = (payload) => ({
  type: STORE_SEARCH_SUGGESTION_RESULT,
  payload,
});

const uiAdditionalSearchKeywordStateChangeRequestAction = () => ({
  type: UI_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE_REQUEST,
});

const storeAdditionalSearchKeywordStateChangeAction = () => ({
  type: STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE,
});

const uiSearchRequestAction = (payload) => ({
  type: UI_SEARCH_REQUEST,
  payload,
});

const uiImgRecognitionAction = (payload) => ({
  type: UI_IMG_RECOGNITION_REQUEST,
  payload
});

const searchRequestedAction = () => ({
  type: SEARCH_REQUESTED,
});

const searchRequestSucceededAction = () => ({
  type: SEARCH_SUCCEEDED,
});

const searchRequestFailedAction = () => ({
  type: SEARCH_FAILED,
});

const storeTextSearchResultAction = (payload) => ({
  type: STORE_TEXT_SEARCH_RESULT,
  payload,
});

const storeVideoSearchResultAction = (payload) => ({
  type: STORE_VIDEO_SEARCH_RESULT,
  payload,
});

const storePhotoSearchResultAction = (payload) => ({
  type: STORE_PHOTO_SEARCH_RESULT,
  payload,
});

const uiClearSearchSuggestionsRequestAction = () => ({
  type: UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST,
});

const storeClearSearchSuggestionsAction = () => ({
  type: STORE_CLEAR_SEARCH_SUGGESTIONS,
});

const storeImgTagsAction = (payload) => ({
  type: STORE_IMG_TAGS_TERM,
  payload
});

export {
  storeSetRandomSearchResultsAction,
  storeSetSliderIndexAction,
  storeImgTagsAction,
  uiImgRecognitionAction,
  uiSearchSuggestionRequestAction,
  searchSuggestionRequest,
  storeSearchTermAction,
  storeTempSearchTermAction,
  searchSuggestionRequestedAction,
  searchSuggestionSucceeded,
  searchSuggestionFailed,
  storeSearchSuggestionResult,
  uiAdditionalSearchKeywordStateChangeRequestAction,
  storeAdditionalSearchKeywordStateChangeAction,
  uiSearchRequestAction,
  searchRequestedAction,
  searchRequestSucceededAction,
  searchRequestFailedAction,
  storeTextSearchResultAction,
  storeVideoSearchResultAction,
  storePhotoSearchResultAction,
  uiClearSearchSuggestionsRequestAction,
  storeClearSearchSuggestionsAction,
};