import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  Container,
  SuccessButton,
  TileSVG,
} from '../../components/simpleComponents';
import { ReactComponent as Keyboard } from '../../assets/icons/keyboard.svg';
import { ReactComponent as Say } from '../../assets/icons/audio.svg';
import { ReactComponent as Photo } from '../../assets/icons/photo.svg';
import textContent from '../../common/textContent';
import {
  uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChangedAction,
} from '../../store/actions/textToSpeachActions';

const useStyles = makeStyles({
  mainImageContainer: {
    textAlign: 'center',
    paddingTop: 60,
    paddingBottom: 32,
  },
  mainImage: {
    width: 180,
    height: 210,
  },
  textCenter: {
    textAlign: 'center',
  },
  subTitleContainer: {
    maxWidth: 560,
    margin: '0 auto',
    paddingLeft: 40,
    paddingRight: 40,
  },
  tileContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tileContainerMobile: {
    flexWrap: 'wrap',
  },
  buttonContainer: {
    textAlign: 'center',
  },
});

const SelectDefaultInputTypeWizardScreen = ({
  selectedAvatarSrc,
  selectedInputPreference,
  setSelectedInputPreference,
  nextStep,
  uiTextToSpeechAutoplay,
  uiWizardStepChanged,
}) => {
  const {
    mainImageContainer,
    mainImage,
    textCenter,
    subTitleContainer,
    tileContainer,
    tileContainerMobile,
    buttonContainer,
  } = useStyles();

  const handleSelectInputPreference = (event) => {
    setSelectedInputPreference(event.currentTarget.getAttribute('name'));
  };

  const matches = useMediaQuery('(max-width:600px)');

  const handleNextStep = () => {
    uiWizardStepChanged();
    nextStep(2);
  };

  useEffect(() => {
    uiTextToSpeechAutoplay(
      textContent.wizardScreens.selectDefaultInputTypeWizardScreen.title
    );
    uiTextToSpeechAutoplay(
      textContent.wizardScreens.selectDefaultInputTypeWizardScreen.subtitle
    );
  }, [uiTextToSpeechAutoplay]);

  return (
    <Container>
      <div className={mainImageContainer}>
        <img className={mainImage} src={selectedAvatarSrc} alt="avatar" />
      </div>
      <div className={textCenter}>
        <Typography variant="h3">
          {textContent.wizardScreens.selectDefaultInputTypeWizardScreen.title}
        </Typography>
      </div>
      <div className={`${textCenter} ${subTitleContainer}`}>
        <Typography variant="h5">
          {
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .subtitle
          }
        </Typography>
      </div>
      <div className={`${tileContainer} ${matches ? tileContainerMobile : ''}`}>
        <TileSVG
          SVG={Keyboard}
          imageAlt="schreiben"
          name="write"
          selected={selectedInputPreference}
          onClick={handleSelectInputPreference}
          label={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .writeTileLabel
          }
        />
        <TileSVG
          SVG={Say}
          imageAlt="sagen"
          name="audioRecord"
          selected={selectedInputPreference}
          onClick={handleSelectInputPreference}
          label={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .sayTileLabel
          }
        />
        <TileSVG
          SVG={Photo}
          imageAlt="foto zeigen"
          name="photo"
          selected={selectedInputPreference}
          onClick={handleSelectInputPreference}
          label={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .photoTileLabel
          }
        />
      </div>
      <div className={buttonContainer}>
        <SuccessButton
          buttonText={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .successButtonText
          }
          onClick={handleNextStep}
        />
      </div>
    </Container>
  );
};

const mapDispatchToProps = {
  uiTextToSpeechAutoplay: uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChanged: uiWizardStepChangedAction,
};

export default connect(
  null,
  mapDispatchToProps
)(SelectDefaultInputTypeWizardScreen);
