import React, { useEffect } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';
import {
  PageTemplate,
  SearchSuggestion,
} from '../../components/advancedComponents';
import textContent from '../../common/textContent';
import { uiTextToSpeechAutoplayRequestAction } from '../../store/actions/textToSpeachActions';
import { useHistory } from "react-router-dom";
import {
  uiSearchRequestAction,
} from '../../store/actions/searchActions';

const RandomSearchPage = ({
  searchTerm,
  uiTextToSpeechAutoplayRequest,
  uiSearchRequest,
}) => {
  const searchSuggestions = useSelector((state) => state.searchState.searchSuggestions, shallowEqual);

  useEffect(() => {
  
        uiTextToSpeechAutoplayRequest([
          'Ich kann dir diese wörter empfehlen',
          //`${textContent.pages.searchSuggestion.title}${searchTerm}`,
          //...[searchTerm, ...searchSuggestions.slice(0, 3)],
          //textContent.components.searchSuggestion.buttonText,
        ]);
    
  },
    [uiTextToSpeechAutoplayRequest
    ]
  );

  let history = useHistory();
  // useEffect(() => {
  //   if (searchSuggestions && searchSuggestions.length === 0) {
  //     uiSearchRequest(searchTerm);
  //     history.replace("/search-result");
  //   }
  // });

  return (
    <>
      <PageTemplate
        headerTextContent={`Ich kann dir diese Wörter empfehlen`}
        avatarFooterButtonText={
          textContent.pages.searchSuggestion.avatarFooterButtonText
        }
        avatarFooterButtonOnClick={() => console.log('avatar button clicked')}
      >
        <SearchSuggestion randomMode={true} searchTerm={searchTerm} />
      </PageTemplate>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    searchState: { searchTerm },
  } = state;
  return {
    searchTerm,
    //searchSuggestions
  };
};


const mapDispatchToProps = {
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiSearchRequest: uiSearchRequestAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RandomSearchPage);
