import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Mute } from '../../../assets/icons/mute.svg';
import { ReactComponent as Muted } from '../../../assets/icons/muted.svg';
import { uiAppAudioMuteStateChangeAction } from '../../../store/actions/userActions';
import textContent from '../../../common/textContent';
import { storeCleanAudioPlayQueueAction } from '../../../store/actions/textToSpeachActions';

const useStyles = makeStyles({
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: '#F2F2F2',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#F2F2F2',
      
    },
    textTransform: 'capitalize',
  },
  textStyle: {
    //fontSize: 30,
    fontSize: 22,
    fontWeight: 'bold'
  },
  textStyleMobile: {
    fontSize: 20,
    fontWeight: 'bold'
  },
});

const HeaderMuteButton = ({ uiAppAudioMuteStateChange, appMuted, storeCleanAudioPlayQueue }) => {
  const [icon, setIcon] = useState(<Mute />);
  const matches = useMediaQuery('(max-width:690px)');

  useEffect(() => {
    if (appMuted === true) {
      setIcon(<Muted width={matches ? 28 : 56} height={matches ? 28 : 56} />);
    } else {
      setIcon(<Mute width={matches ? 28 : 56} height={matches ? 28 : 56} />);
    }
  }, [appMuted, matches]);

  const { buttonStyle, textStyle, textStyleMobile } = useStyles();

  const handleClick = () => {
    storeCleanAudioPlayQueue();
    uiAppAudioMuteStateChange();
  };

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      endIcon={icon}
      className={buttonStyle}
    >
      <Typography className={`${textStyle} ${matches ? textStyleMobile : ''}`}>
        {appMuted
          ? textContent.components.headerMuteButton.muted
          : textContent.components.headerMuteButton.mute}
      </Typography>
    </Button>
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { appMuted },
  } = state;
  return {
    appMuted,
  };
};

const mapDispatchToProps = {
  uiAppAudioMuteStateChange: uiAppAudioMuteStateChangeAction,
  storeCleanAudioPlayQueue: storeCleanAudioPlayQueueAction
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMuteButton);
