import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
const useStyle = makeStyles({
    liningStyle: {
        position: 'absolute',
        zIndex: -100,
        top: '50vh',
        left: '-5vw',
        transformOrigin: '70vh 0vw',
        background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 12%, rgba(0,212,255,1) 100%)',
        transform: 'rotate(15deg)',
        width: '150vw',
        height: '150vh'
    }
});

const Lining = ({})=> {
    const matches = useMediaQuery('(max-width:690px)');
    const {liningStyle}= useStyle();
    if(!matches) return (<div className={liningStyle}></div>);
    else return (<></>);
};

export default Lining;