import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  Tabs,
  AudioRecorder,
  PageTemplate,
  TextSearch,
} from '../../components/advancedComponents';
import textContent from '../../common/textContent';
import { ReactComponent as Keyboard } from '../../assets/icons/keyboard.svg';
import { ReactComponent as Audio } from '../../assets/icons/audio.svg';
import { ReactComponent as Photo } from '../../assets/icons/photo.svg';
import { uiSetSearchUserPreferenceAction } from '../../store/actions/userActions';
import {
  uiTextToSpeechAutoplayRequestAction,
  uiCleanAudioPlayQueueRequestAction
} from '../../store/actions/textToSpeachActions';
import { uiClearSearchSuggestionsRequestAction } from '../../store/actions/searchActions';
import ImageCapture from '../../components/advancedComponents/imageCapture';
// import usePrevious from '../../hooks/usePreviousValue';

const SearchPage = ({
  inputPreference,
  searchTerm,
  additionalKeywordRequested,
  uiSetSearchUserPreference,
  uiTextToSpeechAutoplayRequest,
  uiClearSearchSuggestionsRequest,
  uiCleanAudioPlayQueueRequest,
  loading
}) => {
  const [selectedTab, setSelectedTab] = useState(inputPreference);
  const [pageTitle, setPageTitle] = useState(
    textContent.pages.search.titleWrite
  );

  const useStyles = makeStyles({
    searchTermText: {
      fontSize: 25,
      color: '#000000',
      textAlign: 'center'
    },
  });
  const {searchTermText} = useStyles();

  useEffect(() => {
    uiClearSearchSuggestionsRequest();
    uiCleanAudioPlayQueueRequest();
  }, [uiCleanAudioPlayQueueRequest]);

  useEffect(() => {
    uiCleanAudioPlayQueueRequest();
  }, [uiCleanAudioPlayQueueRequest, selectedTab]);

  // const prevSearchTerm = usePrevious(searchTerm);

  useEffect(() => {
    if (!additionalKeywordRequested && selectedTab === 'write') {
      uiTextToSpeechAutoplayRequest([
        `${textContent.pages.search.titleWrite}`,
        textContent.components.textSearch.buttonText,
      ]);
      setPageTitle(`${textContent.pages.search.titleWrite}`);
    }
    if (!additionalKeywordRequested && selectedTab === 'audioRecord') {
      // if (!prevSearchTerm) {
      uiTextToSpeechAutoplayRequest(`${textContent.pages.search.titleAudio}`);
      // }
      setPageTitle(`${textContent.pages.search.titleAudio}`);
    }
    if (!additionalKeywordRequested && selectedTab === 'photo') {
      uiTextToSpeechAutoplayRequest(`${textContent.pages.search.titlePhoto}`);
      setPageTitle(`${textContent.pages.search.titlePhoto}`);
      // uiTextToSpeechAutoplayRequest(`Diese Funktion wird derzeit entwickelt. Bitte wähle was anderes!`);
      // setPageTitle(`Diese Funktion wird derzeit entwickelt. Bitte wähle was anderes!`);
    }
    if (additionalKeywordRequested && selectedTab === 'write') {
      uiTextToSpeechAutoplayRequest([
        `${searchTerm} ${textContent.pages.search.titleAdditionalKeywordWrite}`,
        textContent.components.textSearch.buttonText,
      ]);
      setPageTitle(
        `${searchTerm} ${textContent.pages.search.titleAdditionalKeywordWrite}`
      );
    }
    if (additionalKeywordRequested && selectedTab === 'audioRecord') {
      uiTextToSpeechAutoplayRequest(
        `${searchTerm}${textContent.pages.search.titleAdditionalKeywordAudio}`
      );
      setPageTitle(
        `${searchTerm}${textContent.pages.search.titleAdditionalKeywordAudio}`
      );
    }
  }, [
    additionalKeywordRequested,
    // prevSearchTerm,
    // searchTerm,
    selectedTab,
    uiTextToSpeechAutoplayRequest,
  ]);


  const handleTabMenuChange = (event) => {
    if(!loading){
      const name = event.currentTarget.getAttribute('name');
      setSelectedTab(name);
      uiSetSearchUserPreference(name);
    }
  };

  return (
    <>
      <PageTemplate
        headerTextContent={pageTitle}
        avatarFooterButtonText={textContent.pages.search.avatarFooterButtonText}
        avatarFooterButtonOnClick={() => console.log('avatar button clicked')}
      >
        <Tabs
          activeTab={selectedTab}
          handleTabMenuChange={handleTabMenuChange}
          tabOneLabel={textContent.pages.search.firstTabLabel}
          tabOneName="write"
          tabOneIcon={Keyboard}
          tabTwoLabel={textContent.pages.search.secondTabLabel}
          tabTwoName="audioRecord"
          tabTwoIcon={Audio}
          tabThreeLabel={textContent.pages.search.thirdTabLabel}
          tabThreeName="photo"
          tabThreeIcon={Photo}
          tabOneComponent={
            <TextSearch
              searchTerm={searchTerm}
              additionalKeywordRequested={additionalKeywordRequested}
            />
          }
          tabTwoComponent={
            <AudioRecorder
              searchTerm={searchTerm}
              additionalKeywordRequested={additionalKeywordRequested}
              setLabel={setPageTitle}
            />
          }
          tabThreeComponent={<ImageCapture
            searchTerm={searchTerm}
            additionalKeywordRequested={additionalKeywordRequested}
            setLabel={setPageTitle}
          >

          </ImageCapture>}
        />
      </PageTemplate>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { inputPreference },
  } = state;

  const {
    loadingState: { loading },
  } = state;

  const {
    searchState: { searchTerm, additionalKeywordRequested },
  } = state;
  return {
    loading,
    inputPreference,
    searchTerm,
    additionalKeywordRequested,
  };
};

const mapDispatchToProps = {
  uiSetSearchUserPreference: uiSetSearchUserPreferenceAction,
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiClearSearchSuggestionsRequest: uiClearSearchSuggestionsRequestAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
