import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import importAvatarImageDynamically from '../../../helpers/importAvatarDynamically';
import { detect } from 'detect-browser';
import { uiShowModalAction } from '../../../store/actions/modalActions';
const browser = detect();

const useStyles = makeStyles({
  buttonStyle: {
    //position:'relative',
    height: 64,
    minHeight: 64,
    paddingRight: 15,
    marginLeft: 24,
    textTransform: 'none',
    backgroundColor: '#353945',
    boxShadow: '0px 6px 12px #0000005A',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#353945',
      boxShadow: '0px 0px 6px 3px rgb(165, 169, 178)'
    },
  },
  buttonStyleApple: {
    '& span' : {
      position: 'relative',
      top: -15
    }
  },
  buttonStyleMobile:{
    boxShadow: '0px 6px 12px #0000005A',
    height: 30,
    minHeight: 30,
    width:100,
    paddingRight: 15,
    marginLeft: 24,
    marginTop: 5,
    marginBottom: 5,
    textTransform: 'none',
    backgroundColor: '#353945',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#353945',
      boxShadow: '0px 0px 6px 3px rgb(165, 169, 178)'
    },
  },
  buttonIconStyle: {
    position: 'relative',
    marginLeft: -28,
    marginRight: 5,
    width: 76,
    height: 90,
  },
  buttonIconStyleMobile: {
    position: 'relative',
    marginRight: 5,
    marginLeft: -30,
    width: 36,
    height: 50,
  },
  textStyle: {
    whiteSpace: 'nowrap',
    //fontSize: 30,
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  textStyleMobile: {
    whiteSpace: 'nowrap',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'right'
  },
});

const AvatarButton = ({ avatar, avatarButtonText, avatarButtonOnClick, uiShowModal }) => {
  const [avatarImageSrc, setAvatarImageSrc] = useState(null);
  const matches = useMediaQuery('(max-width:690px)');
  useEffect(() => {
    importAvatarImageDynamically(avatar)
      .then((r) => {
        setAvatarImageSrc(r.default);
      })
      .catch((e) => console.warn(e));
  }, [avatar]);

  const { buttonStyle, buttonIconStyle, textStyle, buttonStyleMobile, 
    textStyleMobile, buttonIconStyleMobile, buttonStyleApple} = useStyles();
  const isNotApple = (browser && browser.name !== 'safari' && browser.name !== 'ios');
  return (
    <Button
      className={`${matches?buttonStyleMobile:buttonStyle} ${!isNotApple?buttonStyleApple:''}`}
      onClick={()=>uiShowModal()}
    >
      <img src={avatarImageSrc} className={matches?buttonIconStyleMobile:buttonIconStyle} alt={avatar} />
      <Typography className={matches?textStyleMobile:textStyle}>{avatarButtonText}</Typography>
    </Button>
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { avatar },
  } = state;
  return {
    avatar,
  };
};

const mapDispatchToProps = {
  uiShowModal: uiShowModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AvatarButton);
