import React, {useCallback} from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../assets/icons/play_circle.svg';
import PageMargin from '../pageMargin';

const useStyles = makeStyles({
  titleStyle: {
    fontStyle: 'italic',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    fontSize: 20,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  titleStyleMobile: {
    fontStyle: 'italic',
    textAlign: 'center',
    //marginBottom: 15,
    marginBottom: 5,
    //marginTop: 20,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 20,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  baseContainer: {
    flex: 1,
    textAlign: 'center',
    maxHeight: '100%',
  },
  thumbnailContainer: {
    display: 'inline-block',
    minHeight: 0,
    height: '100%',
    position: 'relative',
    //overflow: 'hidden'
    
  },
  playButtonContainer: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '&:hover':{
      boxShadow: '0px 0px 5px 5px rgba(188,188,209,1)'
    }
  },
  playButtonStyle: {
    fill: '#ffffff',
    width: 100,
    height: 100
  },
  thumbnailStyle: {
    flex: 1,
    minHeight: 0,
    maxWidth: '100%',
    maxHeight: '100%',
    //margin: 'auto',
    //maxHeight: '300px',
  },
  thumbnailStyleMobile: {
    maxWidth: '100%',
    minHeight: 0,
    maxHeight: '100%',
    //margin: 'auto',
    //maxHeight: '140px',
    margin: 'auto',
    //maxHeight: 180,
  },
  descStyle: {
    //marginTop: 10
    //maxWidth: 400,
    marginTop: 5,
    margin: 'auto',
    padding: '0 5%',
    fontSize: 18,
    marginBottom: 20,
  },
  descStyleMobile: {
    //marginTop: 10
    // maxWidth: 400,
    marginBottom: 10,
    marginTop: 5,
    margin: 'auto',
    padding: '0 5%',
    fontSize: 18
  },
  flexer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  linkStyle: {
    minHeight: 0,
    display: 'flex'
  },
  spacer: {
    flex: 1
  }
});

const ResultContentVideo = ({ name, uri, description, thumbnailUrl, webSearchUrl }) => {
  const { titleStyle, thumbnailStyle, thumbnailContainer, playButtonContainer, 
    baseContainer, playButtonStyle, thumbnailStyleMobile, titleStyleMobile, descStyle, descStyleMobile, flexer, linkStyle, spacer } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  const history = useHistory();
  const playbackVideoClb= useCallback(()=>history.push('/content-preview',{url: uri, type: "VIDEO", title:name, webSearchUrl}));
  return (
    <PageMargin>
      <div className={flexer}>
      <div>
        <Typography className={matches?titleStyleMobile:titleStyle} variant="h5">
          {name}
        </Typography>
      </div>
      {/* <iframe
        width="100%"
        height="240px"
        src={`https://www.youtube.com/embed/${uri}?autoplay=0`}
        frameBorder="0"
        allowFullScreen
        title={name}
      /> */}
      <a href={uri} className={linkStyle} title="Video angucken" onClick={(e)=>e.preventDefault()}>
        <div className={baseContainer}>
          <div className={thumbnailContainer}>
            <div className={playButtonContainer} onClick={playbackVideoClb}>
              <Arrow className={playButtonStyle}></Arrow>
            </div>
            <img className={matches?thumbnailStyleMobile:thumbnailStyle}
        
            src={thumbnailUrl}></img>

          </div>
        </div>
      </a>
      
      <div className={matches?descStyleMobile:descStyle}>{description?(matches?description.slice(0,50)+'...':description.slice(0,100)+'...'):''}</div>
      <div className={spacer}></div>
      </div>
    </PageMargin>
  );
};

export default ResultContentVideo;
