import React from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow_forward.svg';
import { connect } from 'react-redux';
import { uiTextToSpeechAutoplayRequestAction,
  uiCleanAudioPlayQueueRequestAction } from '../../../store/actions/textToSpeachActions';

const useStyles = makeStyles({
  buttonStyle: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    //top: '170px',
    top: "50%",
    // left: "100%",
    //transform: 'translateY(50%)',
    right: 0,
    zIndex: 10,
    
    //backgroundColor: '#ffffff',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 6px 12px #1623404D',
    
    borderRadius: '12px 0 0 12px',
    '&:before': {
      content: '',
      opacity: '0 !important',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#353945',
      opacity:0.5
      // '&:before': {
      //   content: '',
      //   opacity: 0,
      // },
    },
    '&:hover svg': {
      fill: 'rgba(255, 255, 255, 0.6)'
    }
  },
  arrowStyle: {
    fill: '#353945',
    
  },
  buttonSize: {
    width: 74,
    height: 126,
  },
  buttonSizeMobile: {
    width: 40,
    minWidth: 0,
    height: 70,
  }
});

const SliderCustomNextArrow = ({ className, style, onClick, uiCleanAudioPlayQueueRequest }) => {
  const { buttonStyle, arrowStyle, buttonSize, buttonSizeMobile } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  return (
    <Button
      className={`${buttonStyle} ${className} ${matches?buttonSizeMobile: buttonSize}`}
      style={{ ...style }}
      onClick={()=>{
        uiCleanAudioPlayQueueRequest();
        onClick();
      }}
      startIcon={<Arrow className={arrowStyle}/>}
    />
  );
};
const mapDispatchToProps = {
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction
};

export default connect(null, mapDispatchToProps)(SliderCustomNextArrow);
