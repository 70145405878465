import {
  STORE_TEXT_TO_SPEECH_URL,
  STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE,
  STORE_CLEAN_AUDIO_PLAY_QUEUE,
  STORE_QUEUE_OR_EMPTY_BACKUP, STORE_SET_REVISIT_FLAG
} from '../actions/actionTypes';

const textToSpeechInitState = {
  autoplayAudioURLs: [],
  backupAudioURLs: [],
  revisitFlag: true
};

const textToSpeechReducer = (
  textToSpeechState = textToSpeechInitState,
  action
) => {
  switch (action.type) {
    case STORE_SET_REVISIT_FLAG: {
      const {
        payload
      } = action;
      return {
        ...textToSpeechState,
        revisitFlag: payload,
      };
    }
    case STORE_QUEUE_OR_EMPTY_BACKUP: {
      const {
        payload
      } = action;
      return {
        ...textToSpeechState,
        backupAudioURLs: payload?[...textToSpeechState.backupAudioURLs, ...payload]:[],
      };
    }
    case STORE_TEXT_TO_SPEECH_URL: {
      const {
        payload
      } = action;
      return {
        ...textToSpeechState,
        autoplayAudioURLs: [...textToSpeechState.autoplayAudioURLs, payload],
      };
    }
    case STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE: {
      return {
        ...textToSpeechState,
        autoplayAudioURLs: textToSpeechState.autoplayAudioURLs.slice(1),
      };
    }
    case STORE_CLEAN_AUDIO_PLAY_QUEUE: {
      return {
        ...textToSpeechState,
        autoplayAudioURLs: [],
      };
    }
    default:
      return textToSpeechState;
  }
};

export default textToSpeechReducer;