import { takeLatest, put } from 'redux-saga/effects';
import {
  UI_APP_AUDIO_MUTE_STATE_CHANGE,
  UI_SET_USER_PREFERENCES,
  UI_SET_SEARCH_USER_PREFERENCE,
  UI_SET_RESULT_USER_PREFERENCE,
} from '../actions/actionTypes';
import {
  storeAppAudioMuteStateChangeAction,
  storeSetUserPreferencesAction,
  storeSearchUserPreferenceAction,
  storeResultUserPreferenceAction,
} from '../actions/userActions';

function* storeUserPreferences(action) {
  const { payload } = action;
  yield put(storeSetUserPreferencesAction(payload));
}

function* storeAppMuteChange() {
  yield put(storeAppAudioMuteStateChangeAction());
}

function* setSearchUserPreference(action) {
  const { payload } = action;
  yield put(storeSearchUserPreferenceAction(payload));
}

function* setResultUserPreference(action) {
  const { payload } = action;
  yield put(storeResultUserPreferenceAction(payload));
}

function* userSaga() {
  yield takeLatest(UI_APP_AUDIO_MUTE_STATE_CHANGE, storeAppMuteChange);
  yield takeLatest(UI_SET_USER_PREFERENCES, storeUserPreferences);
  yield takeLatest(UI_SET_SEARCH_USER_PREFERENCE, setSearchUserPreference);
  yield takeLatest(UI_SET_RESULT_USER_PREFERENCE, setResultUserPreference);
}

export default userSaga;
