import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import backButton from '../../assets/icons/back_button.png';
import {
  Header,
  HeaderLinkButton,
  Container,
  HeaderMuteButton,
  Footer,
  AvatarButton,
  FooterBackButton,
  FailedButton,
  SuccessButton,
} from '../../components/simpleComponents';
import { useLocation, useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { Typography, useMediaQuery } from '@material-ui/core';
import Iframe from 'react-iframe'
import { useState } from 'react';
import { useEffect } from 'react';
import { storeAdditionalSearchKeywordStateChangeAction } from '../../store/actions/searchActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Div100vh from 'react-div-100vh';


const useStyles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //flexGrow: 0,
    //flexShrink: 0,
    backgroundColor: '#FFFFFF',
    flexBasis: '100%',
    minHeight: 0,
  },
  contentChildStyle: {
    border: 0,
    flexGrow: 0,
    //flexShrink: 0,
    maxHeight: '100%',
    maxWidth: '100%',
  },
  titleStyle: {
    marginLeft: 30,
    fontSize: 22
  },
  titleStyleMobile: {
    marginLeft: 30,
    fontSize: 20
  },
  headerStyle:{
    flexShrink: 0,
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  backStyle: {
    marginRight: 15,
    flexShrink: 0,
    height: 30,
    width: 30,
    borderRadius:15,
    boxShadow: '0px 0px 12px 3px #0000005A',
    transition:'box-shadow 0.2s ease-in-out',
    '&:hover':{
      boxShadow: '0px 0px 6px 3px rgb(165, 169, 178)'
    }
  },
  contStyle: {
    height: '100%',
    // height: '100vh',
    // maxHeight: '-webkit-fill-available',
    flexDirection: "column",
    display: 'flex'
  },
  redirectStyle: {
      width: '70%'
  },
  buttonStyle: {
    marginLeft: '10%',
    marginRight: '10%'
  }

});

const ContentPreview = ({
}) => {
    const location = useLocation();
    const history = useHistory();

    
    const {url, type, title, webSearchUrl}=location.state || {};
    useEffect(()=>{
    if(!url)
      history.replace('/start');
    });
    console.log(url)
  const { content, titleStyle, contentChildStyle,
     headerStyle, backStyle, contStyle, redirectStyle, buttonStyle, titleStyleMobile } = useStyles();
  let contentChild;

  const [isRedirect, setIsRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    //auto zoom for smaller screens
    document.body.style.zoom='';
    }, []);
  useEffect(()=>{
      (async ()=>{
        if(url && type==='TEXT'){
            setLoading(true);
            const resp = await fetch((process.env.REACT_APP_RANDOM_IFRAME_CHECK_URL || '/.netlify/functions/iframe-check')+'?url='+url);
            const status = await resp.json();
            if(status.forbidden)
                setIsRedirect(true);
            setLoading(false);
        }
      })();
      
  },[url]);
  const matches = useMediaQuery('(max-width:690px)');
  console.log(url);
  if(type === "VIDEO")
    contentChild=(<iframe src={url} 
      allowfullscreen="allowfullscreen"
      mozallowfullscreen="mozallowfullscreen" 
      msallowfullscreen="msallowfullscreen" 
      oallowfullscreen="oallowfullscreen" 
      webkitallowfullscreen="webkitallowfullscreen"
    className={contentChildStyle} height='100%' width='100%' frameBorder="0">
    </iframe>);
    // contentChild=(<ReactPlayer
    //     //ref='player'
    //     className={contentChildStyle}
    //     url={url}
    //     width="100%"
    //     height="100%"
    //     onError={()=>console.log('ERRRRRR')}
    //     controls={true} />);
    else if(type === "TEXT")
    contentChild=loading?(<CircularProgress />):(<Iframe url={url}
        frameBorder="0"
        height='100%'
        width='100%'
        id="myId"
        className={contentChildStyle}
        //className="myClassname"
        display="initial"
        onError={(err)=>console.log(err)}
        position="relative"/>);
    else if(type === "PICTURE")
    contentChild=(<img src={url} onError={()=>console.log("error")} className={contentChildStyle}
     ></img>);
  return (<Div100vh> <div className={contStyle}>
        <div className={headerStyle}>
            <a href={webSearchUrl || ''}><Typography className={matches? titleStyleMobile:titleStyle} >
                {title}
            </Typography></a>
            <img src={backButton} onClick={()=>history.goBack()} className={backStyle}/>
        </div>
        
        {isRedirect?(<div className={redirectStyle}>
            <Typography className={titleStyle} variant="h5">
                Willst du Tobi verlassen um diese seite zu besuchen?
            </Typography>
        </div>):<></>}
        <div className={content}>{!isRedirect?contentChild:(
            <>
            <div className={buttonStyle}>
            <FailedButton
            onClick={()=>history.goBack()}
            buttonText={"nein"}
          />
          </div>
          <div className={buttonStyle}>
          <SuccessButton
            
            onClick={()=>window.location=url}
            buttonText={"ja"}
          />
          </div>
          </>
        )}
        </div>
      </div>
      </Div100vh>);
};

export default ContentPreview;
