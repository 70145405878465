import axios from 'axios';
import { imageSearchURL, imageSearchKey } from '../common/constants';

const searchByFileService = (file) => {
  return axios.post(imageSearchURL, file, {
    headers: {
      'Ocp-Apim-Subscription-Key': imageSearchKey,
      'Content-Type': 'application/octet-stream',
    },
  });
};

export default searchByFileService;
