const initState = {
  loadingState: {
    loading: false,
  },
  modalState: {
    show: false
  },
  userState: {
    avatar: 'manAvatar',
    avatarVoice: '',
    avatarImageSrc: '',
    avatarGender: '',
    inputPreference: 'write',
    resultPreference: 'text',
    firstTimeUser: false,
    onBoardingCompleted: false,
    appMuted: false,
    recordToolTip: true
  },
  textToSpeechState: {
    autoplayAudioURLs: [],
    backupAudioURLs: [],
    revisitFlag: true
  },
  searchState: {
    searchTerm: '',
    searchSuggestions: null,
    additionalKeywordRequested: false,
    textSearchResult: [],
    videoSearchResult: [],
    photoSearchResult: [],
    randomSearchResult: [],
    videoIndex: 0,
    photoIndex: 0,
    textIndex: 0
  },
};

export default initState;