import {
  UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST,
  UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST,
  UI_AUDIO_PLAY_FINISHED,
  TEXT_TO_SPEECH_REQUESTED,
  TEXT_TO_SPEECH_SUCCEEDED,
  TEXT_TO_SPEECH_FAILED,
  STORE_TEXT_TO_SPEECH_URL,
  STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE,
  UI_WIZARD_STEP_CHANGED,
  STORE_CLEAN_AUDIO_PLAY_QUEUE,
  UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST,
  STORE_QUEUE_OR_EMPTY_BACKUP,
  STORE_SET_REVISIT_FLAG
} from './actionTypes';

const storeSetRevisitFlagAction = (payload) => ({
  type: STORE_SET_REVISIT_FLAG,
  payload,
});

const storeQueueOrEmptyBackupAction = (payload) => ({
  type: STORE_QUEUE_OR_EMPTY_BACKUP,
  payload,
});

const uiTextToSpeechAutoplayRequestAction = (payload) => ({
  type: UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST,
  payload,
});

const uiTextToSpeechExplicitPlayRequestAction = (payload) => ({
  type: UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST,
  payload,
});

const uiAudioPlayFinishedAction = () => ({
  type: UI_AUDIO_PLAY_FINISHED,
});

const textToSpeechRequestedAction = () => ({
  type: TEXT_TO_SPEECH_REQUESTED,
});

const textToSpeechSucceededAction = () => ({
  type: TEXT_TO_SPEECH_SUCCEEDED,
});

const textToSpeechFailedAction = () => ({
  type: TEXT_TO_SPEECH_FAILED,
});

const storeTextToSpeechAction = (payload) => ({
  type: STORE_TEXT_TO_SPEECH_URL,
  payload,
});

const storeRemovePlayedAudioFromQueueAction = () => ({
  type: STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE,
});

const uiWizardStepChangedAction = () => ({
  type: UI_WIZARD_STEP_CHANGED,
});

const uiCleanAudioPlayQueueRequestAction = (payload) => ({
  type: UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST,
  payload
});

const storeCleanAudioPlayQueueAction = () => ({
  type: STORE_CLEAN_AUDIO_PLAY_QUEUE,
});

export {
  storeSetRevisitFlagAction,
  storeQueueOrEmptyBackupAction,
  uiTextToSpeechAutoplayRequestAction,
  uiTextToSpeechExplicitPlayRequestAction,
  uiAudioPlayFinishedAction,
  textToSpeechRequestedAction,
  textToSpeechSucceededAction,
  textToSpeechFailedAction,
  storeTextToSpeechAction,
  storeRemovePlayedAudioFromQueueAction,
  uiWizardStepChangedAction,
  uiCleanAudioPlayQueueRequestAction,
  storeCleanAudioPlayQueueAction,
};
