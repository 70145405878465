import React, { useEffect } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';
import {
  PageTemplate,
  SearchSuggestion,
} from '../../components/advancedComponents';
import textContent from '../../common/textContent';
import { uiTextToSpeechAutoplayRequestAction } from '../../store/actions/textToSpeachActions';
import { useHistory } from "react-router-dom";
import {
  uiSearchRequestAction, storeSearchTermAction,
} from '../../store/actions/searchActions';

const SearchSuggestionPage = ({
  searchTerm,
  uiTextToSpeechAutoplayRequest,
  uiSearchRequest,
  storeSearchTerm
}) => {
  const searchSuggestions = useSelector((state) => state.searchState.searchSuggestions, shallowEqual);

  useEffect(() => {
    if (!(searchSuggestions && searchSuggestions.length === 0)) {
      if (searchSuggestions != null)
        uiTextToSpeechAutoplayRequest([
          `${textContent.pages.searchSuggestion.title}${searchTerm}`,
          ...[searchTerm, ...searchSuggestions.slice(0, 3)],
          textContent.components.searchSuggestion.buttonText,
        ]);
    }
  },
    [searchTerm, searchSuggestions, uiTextToSpeechAutoplayRequest
    ]
  );

  let history = useHistory();
  useEffect(() => {
    if (searchSuggestions && searchSuggestions.length === 0) {
      storeSearchTerm(searchTerm);
      uiSearchRequest(searchTerm);
      history.replace("/search-result");
    }
  });

  return (
    <>
      <PageTemplate
        headerTextContent={`${textContent.pages.searchSuggestion.title}${searchTerm}`}
        avatarFooterButtonText={
          textContent.pages.searchSuggestion.avatarFooterButtonText
        }
        avatarFooterButtonOnClick={() => console.log('avatar button clicked')}
      >
        <SearchSuggestion searchTerm={searchTerm} />
      </PageTemplate>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    searchState: { searchTerm },
  } = state;
  return {
    searchTerm,
    //searchSuggestions
  };
};


const mapDispatchToProps = {
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiSearchRequest: uiSearchRequestAction,
  storeSearchTerm: storeSearchTermAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchSuggestionPage);
