export default {
    pages: {
        home: {
            title: 'Frag Tobi',
            subtitle: 'Einfach suchen im Internet',
            linkButton: 'Start',
            logoText: 'Ein Projekt der',
        },
        start: {
            title: 'Suchst du etwas? Oder soll ich dir etwas zeigen?',
            contentLinkButton: {
                search: 'Ich suche etwas',
                suggest: 'Zeig mir etwas',
            },
            avatarButtonText: 'Hilfe',
        },
        search: {
            titleWrite: 'Was interessiert dich? Schreibe bitte EIN WORT!',
            titleAudio: 'Was interessiert dich? Sag mir bitte EIN WORT!',
            titlePhoto: 'Möchtest du ein Foto machen? Oder hast du schon ein Foto und möchtest es hochladen?',
            titleAdditionalKeywordWrite: ' war dein erstes Wort. Schreibe noch eins!',
            titleAdditionalKeywordAudio: ' war dein erstes Wort. Sag noch ein Wort',
            firstTabLabel: 'Schreiben',
            secondTabLabel: 'Sagen',
            thirdTabLabel: 'Foto',
            avatarFooterButtonText: 'Hilfe?',
        },
        searchSuggestion: {
            title: 'Das ist eine Auswahl für ',
            avatarFooterButtonText: 'Hilfe?',
        },
        searchResult: {
            title: ' ',
            avatarFooterButtonText: 'Hilfe?',
            firstTabLabel: 'Video',
            secondTabLabel: 'Text',
            thirdTabLabel: 'Bilder',
        },
    },
    components: {
        audioRecordButton: {
            buttonText: 'Fertig!',
        },
        headerLinkButton: {
            buttonTextDisabled: 'Start',
            buttonText: 'Start',
        },
        headerMuteButton: {
            mute: 'Ton: Ein',
            muted: 'Ton: Aus',
        },
        textSearch: {
            inputPlaceholder: 'schreibe etwas...',
            inputPlaceholderAdditionalKeyword: 'schreibe noch etwas...',
            buttonText: 'Dieses Wort suchen',
            confirmationFailedButton: 'Nochmal',
            confirmationSuccessButton: 'Ist gut!',
            confirmationContentButton: 'noch ein Wort',
        },
        searchSuggestion: {
            buttonText: 'noch ein Wort',
        },
    },
    wizardScreens: {
        selectAssistantWizardScreen: {
            title: 'Tobi ändern',
            subtitle: 'Ich kann auch noch anders aussehen! Klicke auf das Bild, das dir am Besten gefällt.',
            successButtonText: 'Passt so!',
        },
        selectDefaultInputTypeWizardScreen: {
            title: 'Lieblings-Suche',
            subtitle: ' Wie suchst du am Liebsten?',
            writeTileLabel: 'Schreiben',
            sayTileLabel: 'Sagen',
            photoTileLabel: 'Foto zeigen',
            successButtonText: 'Passt so!',
        },
        selectDefaultResultTypeWizardScreen: {
            title: 'Lieblings-Ergebnis',
            subtitle: 'Bist du jemand, der gerne Videos sieht, oder lieber Bilder? Oder liest du am Liebsten Texte?',
            videoLabel: 'Video',
            textLabel: 'Text',
            imageLabel: 'Bilder',
            successButtonText: 'Passt so!',
        },
    },
};