import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ThumbsUp } from '../../../assets/icons/thumb_up.svg';
import { connect } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';


const useStyles = makeStyles({
  button: {
    textTransform: 'lowercase',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#4EAD64',
    },
  },
  buttonSize:{
    marginTop: 20,
    marginBottom: 20,
  },
  
  buttonSizeMobile: {
    fontSize: 20,
    marginTop: 10,
    marginBottom: 15,
  },
  iconStyleMobile:{
    height: 20,
    width: 20
  },
  iconStyle:{},
  buttonBackground: {
    backgroundColor: '#4EAD64',
  },
  buttonBackgroundReading: {
    backgroundColor: '#353945',
  },
  
});

const SuccessButton = ({ buttonText, onClick, autoplayAudioText, additionalStyle}) => {
  const { button, buttonBackground, buttonBackgroundReading, buttonSize, buttonSizeMobile,
    iconStyleMobile,iconStyle} = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  return (
    <Button
      variant="contained"
      className={`${button} ${matches?buttonSizeMobile:buttonSize} ${autoplayAudioText !== buttonText ? buttonBackground : buttonBackgroundReading} ${additionalStyle}`}
      startIcon={<ThumbsUp className={matches?iconStyleMobile:iconStyle}/>}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};
const mapStateToProps = (state) => {
  const autoplayAudioText = state.textToSpeechState.autoplayAudioURLs[0]
    ? state.textToSpeechState.autoplayAudioURLs[0].text
    : '';

  return {
    autoplayAudioText,
  };
};

export default connect(mapStateToProps)(SuccessButton);
