import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import configureStore from './store/configureStore';
import theme from './theme/theme';
import App from './App';
import { LoadingIndicator } from './components/simpleComponents';

const Store = configureStore();
const persistor = persistStore(Store);

const Root = () => (
  <Provider store={Store}>
    <PersistGate loading={<LoadingIndicator />} persistor={persistor}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <App />
          </CssBaseline>
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
);

export default Root;
