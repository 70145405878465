import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import tobiAvatar from '../../../assets/icons/back.svg';
import { uiCleanAudioPlayQueueRequestAction } from '../../../store/actions/textToSpeachActions';
import { uiClearSearchSuggestionsRequestAction, uiAdditionalSearchKeywordStateChangeRequestAction } from '../../../store/actions/searchActions';

const useStyles = makeStyles({
  buttonStyle: {
    position: 'absolute',
    top: -34,
    right: 65,
    width: 128,
    height: 128,
    borderRadius: '50%',
    backgroundColor: '#3C6DFF',
    
    '&:hover': {
      backgroundColor: '#3C6DFF',
      boxShadow: '0px 0px 6px 3px rgb(165, 169, 178)',
      transition:'box-shadow 0.2s ease-in-out',
    },
  },
  buttonStyleMobile: {
    position: 'absolute',
    top: -14,
    right: 25,
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: '#3C6DFF',
    boxShadow: '0px 6px 12px #0000005A',
    '&:hover': {
      backgroundColor: '#3C6DFF',
    },
  },
  iconStyle:{

  },
  iconsStyleMobile:{
    width: 30,
    height: 30,
  }
});

const BackButton = ({ uiCleanAudioPlayQueueRequest, uiAdditionalSearchKeywordStateChangeRequest, additionalKeywordRequested }) => {
  const { buttonStyle, buttonStyleMobile, iconStyle, iconsStyleMobile } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  const history = useHistory();
  const handleClick = () => {
    uiCleanAudioPlayQueueRequest();
    additionalKeywordRequested && uiAdditionalSearchKeywordStateChangeRequest();
    history.goBack();
  };

  return (
    <Button className={matches ? buttonStyleMobile : buttonStyle} onClick={handleClick}>
      <img src={tobiAvatar} className={matches?iconsStyleMobile:iconStyle} alt="avatar" />
    </Button>
  );
};

const mapStateToProps = (state) => {

  return {
    additionalKeywordRequested: state.searchState.additionalKeywordRequested
    //searchSuggestions
  };
};
const mapDispatchToProps = {
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction,
  uiClearSearchSuggestionsRequest: uiClearSearchSuggestionsRequestAction,
  uiAdditionalSearchKeywordStateChangeRequest: uiAdditionalSearchKeywordStateChangeRequestAction
};

export default connect(mapStateToProps, mapDispatchToProps)(BackButton);
