import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import textContent from '../../../common/textContent';
import { connect } from 'react-redux';
import { uiAdditionalSearchKeywordStateChangeRequestAction } from '../../../store/actions/searchActions';

const useStyles = makeStyles({
  buttonStyle: {
    backgroundColor: '#353945!important',
    color: '#ffffff',
    '&hover': {
      backgroundColor: '#353945',
    },
    '&.MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 30,
    },
    textTransform: 'lowercase',
  },
  buttonDisabledStyle: {
    backgroundColor: '#F2F2F2!important',
  },
  textStyle: {
    //fontSize: 30,
    textTransform: 'none',
    fontSize: 22,
    fontWeight: 'bold'
  },
  textStyleMobile: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  textDisabledStyle: {
    //fontSize: 30,
    fontSize: 20,
    textTransform: 'none',
    color: '#353945',
  },
  textDisabledStyleMobile: {
    fontSize: 18,
  },
});

const HeaderLinkButton = ({ uiAdditionalSearchKeywordStateChangeRequest, additionalKeywordRequested }) => {
  const { pathname } = useLocation();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(pathname === '/start');
  }, [pathname]);

  const matches = useMediaQuery('(max-width:690px)');

  const {
    buttonStyle,
    buttonDisabledStyle,
    textStyle,
    textStyleMobile,
    textDisabledStyle,
    textDisabledStyleMobile,
  } = useStyles();
  return (
    <Button
      classes={{ root: buttonStyle, disabled: buttonDisabledStyle }}
      className={`${matches ? textStyleMobile : ''}`}
      variant="contained"
      component={RouterLink}
      onClick={() => { if (additionalKeywordRequested) uiAdditionalSearchKeywordStateChangeRequest(); }}
      to="/start"
      startIcon={
        <Home
          fill={`${disabled ? '#353945' : '#ffffff'}`}
          width={matches ? 15 : 25}
          height={matches ? 15 : 25}
        />
      }
      disabled={disabled}
    >
      <Typography
        className={`${disabled ? textDisabledStyle : textStyle} ${
          matches ? textStyleMobile : ''
          } ${matches && disabled ? textDisabledStyleMobile : ''}`}
      >
        {`${
          disabled
            ? textContent.components.headerLinkButton.buttonTextDisabled
            : textContent.components.headerLinkButton.buttonText
          }`}
      </Typography>
    </Button>
  );
};

const mapStateToProps = (state) => {

  return {
    additionalKeywordRequested: state.searchState.additionalKeywordRequested
    //searchSuggestions
  };
};
const mapDispatchToProps = {
  uiAdditionalSearchKeywordStateChangeRequest: uiAdditionalSearchKeywordStateChangeRequestAction
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinkButton);
