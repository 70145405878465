// image search service
const imageSearchURL =
  'https://westeurope.api.cognitive.microsoft.com/vision/v1.0/analyze?visualFeatures=Tags';
const imageSearchKey = 'b15a92d0a72e4dff8f4a272e83c087a4';

// search service
const searchURL = 'https://api.cognitive.microsoft.com/bingcustomsearch/v7.0/';
const searchKey = '664319af620545669d8cd3410cf0cb9a';
const webAPI = 'e66d0571-76c6-49d8-a6ef-990c738dac48';

// suggestion service
const suggestURL = 'https://api.cognitive.microsoft.com/bing/v7.0/Suggestions';
const suggestSubscriptionKey = '54505d7985d94743b9a06242a965cd2f';

// translation service
const translatorToken = 'c3bd66ff475f4a138b29af30adcd3ab9';
const translatorUrl =
  'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=de';

// text to speech service
const issueTokenURL =
  'https://westeurope.api.cognitive.microsoft.com/sts/v1.0/issuetoken';
const ocpapimsubKey = 'fe1fd31b57e646f79703ee8b833aa1e3';
const textToSpeechPostURL =
  'https://westeurope.tts.speech.microsoft.com/cognitiveservices/v1';

// speech to text
const speechToTextUrl =
  'https://westeurope.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=de-DE&format=detailed';

const randomSearchUrl = "/.netlify/functions/random-search";

export {
  imageSearchURL,
  imageSearchKey,
  searchURL,
  searchKey,
  webAPI,
  suggestURL,
  suggestSubscriptionKey,
  translatorToken,
  translatorUrl,
  issueTokenURL,
  ocpapimsubKey,
  textToSpeechPostURL,
  speechToTextUrl,
};