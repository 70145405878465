import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { TileSVG } from '../../simpleComponents';

const useStyles = makeStyles({
  tabsContainer: {
    display: 'flex',
    //flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F2F2F2',
    padding: '0 64px',
    //width: '100%',
    margin: 0,
    //padding: '0 54px',
  },
  tabsContainerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F2F2F2',
    padding: '0 34px',
    //width: '100%',
    margin: 0,
    //padding: '0 54px',
  },
  additionalCardStyle: {
    width: 160,
    height: 106,
    margin: 0,
    borderRadius: '3px 3px 0px 0px',
    boxShadow: 'none',
    
  },
  additionalCardStylesMobile: {
    width: 85,
    height: 50,
    margin: 0,
    borderRadius: '3px 3px 0px 0px',
    boxShadow: 'none',
    padding: 0
  },
  tabPanelsContainer: {
    flex: 1,
    minHeight: 0
  },
  tabsView: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});

const Tabs = ({
  activeTab,
  handleTabMenuChange,
  tabOneLabel,
  tabOneName,
  tabOneIcon,
  tabTwoLabel,
  tabTwoName,
  tabTwoIcon,
  tabThreeLabel,
  tabThreeName,
  tabThreeIcon,
  tabOneComponent,
  tabTwoComponent,
  tabThreeComponent,
}) => {
  const {
    tabsContainer,
    additionalCardStyle,
    additionalCardStylesMobile,
    tabPanelsContainer,
    tabsContainerMobile,
    tabsView
  } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');

  return (
    <div className={tabsView}>
      <div className={matches ?  tabsContainerMobile : tabsContainer}>
        <TileSVG
          SVG={tabOneIcon}
          imageAlt={tabOneLabel}
          name={tabOneName}
          selected={activeTab}
          onClick={handleTabMenuChange}
          label={tabOneLabel}
          additionalCardStyle={
            matches ? additionalCardStylesMobile : additionalCardStyle
          }
          inverseColors
        />
        <TileSVG
          SVG={tabTwoIcon}
          imageAlt={tabTwoLabel}
          name={tabTwoName}
          selected={activeTab}
          onClick={handleTabMenuChange}
          label={tabTwoLabel}
          additionalCardStyle={
            matches ? additionalCardStylesMobile : additionalCardStyle
          }
          inverseColors
        />
        <TileSVG
          SVG={tabThreeIcon}
          imageAlt={tabThreeLabel}
          name={tabThreeName}
          selected={activeTab}
          onClick={handleTabMenuChange}
          label={tabThreeLabel}
          additionalCardStyle={
            matches ? additionalCardStylesMobile : additionalCardStyle
          }
          inverseColors
        />
      </div>
      <div className={tabPanelsContainer}>
        {activeTab === tabOneName && tabOneComponent}
        {activeTab === tabTwoName && tabTwoComponent}
        {activeTab === tabThreeName && tabThreeComponent}
      </div>
    </div>
  );
};

export default Tabs;
