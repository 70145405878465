import React, {useEffect, useCallback} from 'react';
import { connect } from 'react-redux';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayAudioButton from '../playAudioButton';
import { uiTextToSpeechAutoplayRequestAction,
uiCleanAudioPlayQueueRequestAction } from '../../../store/actions/textToSpeachActions';
import { useHistory } from 'react-router-dom';
import PageMargin from '../pageMargin';

const useStyles = makeStyles({
  container: {
    cursor: "pointer",
    backgroundColor: '#F4F4F4',
    margin: 'auto',
    '&:hover':{
      boxShadow: '0px 0px 12px 6px rgb(165, 169, 178)'
    }
  },
  containerSize: {
    //maxHeight: 400,
    overflow: 'hidden',
    // marginLeft:34,
    // marginRight:34,
    padding: 25,
    marginTop: 20,
    marginBottom: 20,
  },
  containerSizeMobile: {
    overflow: 'hidden',
    //maxHeight: 200,
    // marginLeft:34,
    // marginRight:34,
    padding: 10,
    marginTop: 20,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    textDecoration: 'underline',
    color: '#5C656B',
  },
  titleMobile: {
    fontSize: 20,
    textDecoration: 'underline',
    color: '#5C656B',
  },
  textContent: {
    fontSize: 18,
    color: '#5C656B',
  },
  textContentMobile: {
    fontSize: 18,
    color: '#5C656B',
  },
  playbackStyle: {
    float: 'right',
    marginRight: 5,
  },
  playbackSize: {
    width: 80,
    height: 80
  },
  playbackSizeMobile: {
    width: 40,
    height: 40
  },
  textContainer:{
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
});

const ResultContentText = ({
  name,
  snippet,
  url,
  uiTextToSpeechAutoplayRequest,
  uiCleanAudioPlayQueueRequest,
  webSearchUrl
}) => {
  const matches = useMediaQuery('(max-width:690px)');
  const { container, title, textContent, textContentMobile, titleMobile, playbackStyle,
  containerSize, containerSizeMobile, playbackSizeMobile, playbackSize, textContainer } = useStyles();
  const history = useHistory();
  const playbackTextClb= useCallback(()=>history.push('/content-preview',{url: url.substring(0,4)==='http'?url:'http://'+url, type: "TEXT", title:name, webSearchUrl}));
  const handlePlayAudioClick = (e) => {
    e.stopPropagation();
    uiCleanAudioPlayQueueRequest();
    uiTextToSpeechAutoplayRequest([name,snippet]);
    //uiTextToSpeechAutoplayRequest(snippet);
  };

  return (
    
    <PageMargin>
      <div className={textContainer}>
      <a title="Artikel lesen" onClick={(e)=>e.preventDefault()}>
    <div className={`${container} ${matches?containerSizeMobile:containerSize}`} onClick={playbackTextClb}>
      
      <PlayAudioButton className={`${playbackStyle} ${matches?playbackSizeMobile:playbackSize}`} onClick={handlePlayAudioClick} />
      
      <Typography className={matches?titleMobile:title}>
        {name}</Typography>
        
      <Typography className={matches?textContentMobile:textContent}>{matches?(snippet.length>150?(snippet.substring(0,150)+'...'):snippet):snippet}</Typography>
    </div>
    </a>
    </div>
    </PageMargin>
    
  );
};

const mapDispatchToProps = {
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction
};

export default connect(null, mapDispatchToProps)(ResultContentText);
