import React from 'react';
import { Card, CardContent, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cardStyle: {
    width: 178,
    height: 178,
    margin: 30,
    textAlign: 'center',
    '&:hover': {
      background: '#353945',
    },
    cursor: 'pointer',
  },
  cardStyleMobile: {
    // width: '15wh',
    // height: '15wh',
    minHeight: 0,
    minWidth: 0,
    margin: 10,
    textAlign: 'center',
    '&:hover': {
      background: '#353945',
    },
    cursor: 'pointer',
  },
  selectedStyle: {
    backgroundColor: '#353945',
  },
  imageStyle: {
    width: 118,
    height: 138,
  },
  imageStyleMobile: {
    width: 50,
    height: 50,
  },
});

const Tile = ({ image, imageAlt, name, selected, onClick }) => {
  const { cardStyle, selectedStyle, imageStyle, cardStyleMobile, imageStyleMobile } = useStyles();
  const itemSelected = selected === name;
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <Card
      className={`${matches?cardStyleMobile:cardStyle} ${itemSelected ? selectedStyle : ''}`}
      name={name}
      onClick={onClick}
    >
      <CardContent>
        <img
          src={image}
          alt={imageAlt}
          className={matches?imageStyleMobile:imageStyle}
          style={{ fill: '#000000' }}
        />
      </CardContent>
    </Card>
  );
};

export default Tile;
