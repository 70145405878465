import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import userReducer from './userReducer';
import textToSpeechReducer from './textToSpeechReducer';
import searchReducer from './searchReducer';
import modalReducer from './modalReducer';

const rootReducer = combineReducers({
  loadingState: loadingReducer,
  userState: userReducer,
  textToSpeechState: textToSpeechReducer,
  searchState: searchReducer,
  modalState: modalReducer
});

export default rootReducer;
