import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Tabs,
  PageTemplate,
  ResultContent,
} from '../../components/advancedComponents';
import textContent from '../../common/textContent';
import { ReactComponent as Video } from '../../assets/icons/video.svg';
import { ReactComponent as Text } from '../../assets/icons/text.svg';
import { ReactComponent as Image } from '../../assets/icons/image.svg';
import { uiSetResultUserPreferenceAction } from '../../store/actions/userActions';
import { uiTextToSpeechAutoplayRequestAction, uiCleanAudioPlayQueueRequestAction } from '../../store/actions/textToSpeachActions';

const SearchResultPage = ({
  resultPreference,
  searchTerm,
  textSearchResult,
  videoSearchResult,
  photoSearchResult,
  uiSetResultUserPreference,
  uiTextToSpeechAutoplayRequest,
  uiCleanAudioPlayQueueRequest
}) => {
  const [selectedTab, setSelectedTab] = useState(resultPreference);

  useEffect(() => {
    uiCleanAudioPlayQueueRequest();
    uiTextToSpeechAutoplayRequest(
      `${textContent.pages.searchResult.title}${searchTerm}`
    );
  }, [searchTerm, uiTextToSpeechAutoplayRequest, uiCleanAudioPlayQueueRequest]);

  const handleTabMenuChange = (event) => {
    const name = event.currentTarget.getAttribute('name');
    setSelectedTab(name);
    uiSetResultUserPreference(name);
  };

  return (
    <>
      <PageTemplate
        headerTextContent={`${textContent.pages.searchResult.title}${searchTerm}`}
        avatarFooterButtonText={
          textContent.pages.searchResult.avatarFooterButtonText
        }
        avatarFooterButtonOnClick={() => console.log('avatar button clicked')}
      >
        <Tabs
          activeTab={selectedTab}
          handleTabMenuChange={handleTabMenuChange}
          tabOneLabel={textContent.pages.searchResult.firstTabLabel}
          tabOneName="video"
          tabOneIcon={Video}
          tabTwoLabel={textContent.pages.searchResult.secondTabLabel}
          tabTwoName="text"
          tabTwoIcon={Text}
          tabThreeLabel={textContent.pages.searchResult.thirdTabLabel}
          tabThreeName="image"
          tabThreeIcon={Image}
          tabOneComponent={<ResultContent contentData={videoSearchResult} />}
          tabTwoComponent={<ResultContent contentData={textSearchResult} />}
          tabThreeComponent={<ResultContent contentData={photoSearchResult} />}
        />
      </PageTemplate>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { resultPreference },
    searchState: {
      searchTerm,
      textSearchResult,
      videoSearchResult,
      photoSearchResult,
    },
  } = state;

  return {
    resultPreference,
    searchTerm,
    textSearchResult,
    videoSearchResult,
    photoSearchResult,
  };
};

const mapDispatchToProps = {
  uiSetResultUserPreference: uiSetResultUserPreferenceAction,
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPage);
