import axios from 'axios';
import {
  issueTokenURL,
  ocpapimsubKey,
  textToSpeechPostURL,
} from '../common/constants';

const textToSpeechService = (assistantGender, assistantVoice, text) => {
  const gender = assistantGender || 'Male';
  const voice = assistantVoice || 'de-AT-Michael';
  const prefix = `<speak version='1.0' xml:lang='de-DE'><voice xml:lang='de-DE' xml:gender='${gender}'\n    name='${voice}'>\n`;
  const suffix = '\n</voice></speak>';

  return axios
    .post(
      issueTokenURL,
      {},
      {
        headers: {
          'Ocp-Apim-Subscription-Key': ocpapimsubKey,
        },
      }
    )
    .then((result) => {
      return axios
        .post(textToSpeechPostURL, prefix + text + suffix, {
          method: 'POST',
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/ssml+xml',
            'X-Microsoft-OutputFormat': 'audio-16khz-64kbitrate-mono-mp3',
            Authorization: `Bearer ${result.data}`,
          },
        })
        .then((response) => {
          return URL.createObjectURL(response.data);
        });
    })
    .catch((err) => console.warn(err));
};

export default textToSpeechService;
