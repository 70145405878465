import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    progress: {
      position: 'absolute',
      top: '50vh',
      left: '50%',

      zIndex: '100',
    },
    circle: {
      position: 'relative',
      transform: 'translateX(-50%)'
    }
  })
);

const LoadingIndicator = ({ loading }) => {
  const { progress, circle } = useStyles();

  return loading ? (
    <div className={progress}>
      <div className={circle}>
    <CircularProgress  data-testid="Loading" />
    </div>
    </div>
  ) : null;
};

const mapStateToProps = ({ loadingState }) => {
  const { loading } = loadingState;
  return {
    loading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingIndicator);
