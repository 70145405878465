import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTemplate } from '../../components/advancedComponents';
import { ContentLinkButton } from '../../components/simpleComponents';
import { uiTextToSpeechAutoplayRequestAction } from '../../store/actions/textToSpeachActions';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {ReactComponent as SuggestIcon} from '../../assets/icons/suggest.svg';
import textContent from '../../common/textContent';
import { makeStyles, FormHelperText, useMediaQuery } from '@material-ui/core';
import PageMargin from '../../components/simpleComponents/pageMargin';
import { storeSetRandomSearchResultsAction } from '../../store/actions/searchActions';

const useStyle = makeStyles({
  buttonContainer: {
    position: 'relative',
    // paddingTop: 25,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  
  buttonMargin: {
    display: "flex",
    flex: 4,
    // marginTop: 25,
    // marginBottom: 25
  },
  spacer: {
    flex: 5
  }
});
const StartPage = ({ uiTextToSpeechAutoplay, storeSetRandomSearchResults }) => {
  const matches = useMediaQuery('(max-width:690px)');
  useEffect(() => {
    uiTextToSpeechAutoplay([
      textContent.pages.start.title,
      textContent.pages.start.contentLinkButton.search,
      //"Ich kann dir derzeit nichts zeigen, da diese Funktion eben entwickelt wird"
      textContent.pages.start.contentLinkButton.suggest,
    ]);
  }, [uiTextToSpeechAutoplay]);
  const {buttonContainer, conatinerMobile, container, buttonMargin, innerContainer, spacer} = useStyle();
  return (
    
    <PageTemplate
      headerTextContent={textContent.pages.start.title}
      avatarFooterButtonText={textContent.pages.start.avatarButtonText}
      showOnlyAvatarFooterButton
      avatarFooterButtonOnClick={() => console.log('avatar button clicked')}
    >
      <div className={innerContainer}>
      <PageMargin>
        
      <div className={buttonContainer}>
      <div className={spacer}></div>
      <ContentLinkButton
        linkTo="/search"
        additionalStyle={buttonMargin}
        buttonText={textContent.pages.start.contentLinkButton.search}
        startIcon={SearchIcon}
      />
      <div className={spacer}></div>
      
      <ContentLinkButton
        linkTo="/random-search"
        additionalStyle={buttonMargin}
        buttonText={textContent.pages.start.contentLinkButton.suggest}
        startIcon={SuggestIcon}
        onClick={()=>storeSetRandomSearchResults([])}
        //disabled={true}
      />
      <div className={spacer}></div>
      <div className={spacer}></div>
      <div className={spacer}></div>
      <div className={spacer}></div>
      </div>
      
      </PageMargin>
      </div>
    </PageTemplate>
    
  );
};

const mapDispatchToProps = {
  uiTextToSpeechAutoplay: uiTextToSpeechAutoplayRequestAction,
  storeSetRandomSearchResults: storeSetRandomSearchResultsAction
};

export default connect(null, mapDispatchToProps)(StartPage);
