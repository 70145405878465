import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import {
  HomePage,
  StartPage,
  SearchPage,
  OnBoarding,
  SearchResultPage,
  SearchSuggestionPage,
} from '../pages';
import ConditionalRoute from './conditionalRoute';
import RandomSearch from '../pages/randomSearch';
import ContentPreview from '../pages/contentPreview';

const AppRouter = ({ onBoardingCompleted }) => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <ConditionalRoute
        condition={!onBoardingCompleted}
        exact
        path="/onboarding"
        component={OnBoarding}
      />
      <Route exact path="/start" component={StartPage} />
      <Route exact path="/search" component={SearchPage} />
      <Route exact path="/random-search" component={RandomSearch} />
      <Route exact path="/content-preview" component={ContentPreview} />
      <Route exact path="/search-result" component={SearchResultPage} />
      <Route exact path="/search-suggestion" component={SearchSuggestionPage} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { onBoardingCompleted },
  } = state;
  return {
    onBoardingCompleted,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
