import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SelectAssistantWizardScreen,
  SelectDefaultInputTypeWizardScreen,
  SelectDefaultResultTypeWizardScreen,
} from '../../wizardScreens';
import { Fade } from '../../components/simpleComponents';
import { uiSetUserPreferencesAction } from '../../store/actions/userActions';
import importAvatarImageDynamically from '../../helpers/importAvatarDynamically';
import MuteScreen from '../../wizardScreens/muteScreen';
import Div100vh from 'react-div-100vh';
import { makeStyles } from '@material-ui/core';
import { PageTemplate } from '../../components/advancedComponents';
import IntroScreen from '../../wizardScreens/introScreen'


const useStyles = makeStyles({
  mainSize: {
    height: "100%"
  }
});
const OnBoardingPage = ({ setUserPreferences }) => {
  const [step, setStep] = useState(0);
  const [avatar, setAvatar] = useState('manAvatar');
  const [avatarImageSrc, setAvatarImageSrc] = useState('');
  const [inputPreference, setInputPreference] = useState('write');
  const [resultPreference, setResultPreference] = useState('text');

  const {mainSize} = useStyles();

  useEffect(() => {
    importAvatarImageDynamically(avatar)
      .then((r) => setAvatarImageSrc(r.default))
      .catch((e) => console.warn(e));
  }, [avatar]);

  const handleNextStep = (index) => {
    setStep(index);
  };

  const history = useHistory();
  const handlePreferenceSubmit = () => {
    setUserPreferences({
      avatar,
      avatarImageSrc,
      // inputPreference,
      // resultPreference,
      onBoardingCompleted: true,
    });
    history.push('/start');
  };

  return (
    <PageTemplate onlyContainer={true}>
    {step ===  0 && (
        <Fade fadeIn className={mainSize}>
          <MuteScreen
            selectedAvatar={avatar}
            setSelectedAvatar={setAvatar}
            selectedAvatarSrc={avatarImageSrc}
            nextStep={handleNextStep}
          />
        </Fade>
      )}
      {step ===  1 && (
        <Fade fadeIn className={mainSize}>
          <IntroScreen
            selectedAvatar={avatar}
            setSelectedAvatar={setAvatar}
            selectedAvatarSrc={avatarImageSrc}
            nextStep={handleNextStep}
          />
        </Fade>
      )}
      {step ===  2 && (
        <Fade fadeIn className={mainSize}>
          <SelectAssistantWizardScreen
            selectedAvatar={avatar}
            setSelectedAvatar={setAvatar}
            selectedAvatarSrc={avatarImageSrc}
            nextStep={handlePreferenceSubmit}
          />
        </Fade>
      )}
      {/* {step === 1 && (
        <Fade fadeIn>
          <SelectDefaultInputTypeWizardScreen
            selectedAvatarSrc={avatarImageSrc}
            selectedInputPreference={inputPreference}
            setSelectedInputPreference={setInputPreference}
            nextStep={handleNextStep}
          />
        </Fade>
      )}
      {step === 2 && (
        <Fade fadeIn>
          <SelectDefaultResultTypeWizardScreen
            selectedAvatarSrc={avatarImageSrc}
            selectedResultPreference={resultPreference}
            setSelectedResultPreference={setResultPreference}
            submit={handlePreferenceSubmit}
          />
        </Fade>
      )} */}
    </PageTemplate>
  );
};

const mapDispatchToProps = {
  setUserPreferences: uiSetUserPreferencesAction,
};

export default connect(null, mapDispatchToProps)(OnBoardingPage);
