import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import backButton from '../../../assets/icons/back_button.png';
// import videos from '../../../assets/icons/videos.png';
import { useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import importAvatarImageDynamically from '../../../helpers/importAvatarDynamically';
import { ContentButton, HeaderLinkButton, TileSVG, SuccessButton, Tile } from '../../simpleComponents';
import { ReactComponent as plus } from '../../../assets/icons/arrow.svg';
import { uiHideModalAction } from '../../../store/actions/modalActions';
import Div100vh from 'react-div-100vh';
import { uiTextToSpeechAutoplayRequestAction } from '../../../store/actions/textToSpeachActions';
import { ReactComponent as Keyboard } from '../../../assets/icons/keyboard.svg';
import { ReactComponent as Say } from '../../../assets/icons/audio.svg';
import { ReactComponent as Photo } from '../../../assets/icons/photo.svg';
import { ReactComponent as Video } from '../../../assets/icons/video.svg';
import { ReactComponent as Text } from '../../../assets/icons/text.svg';
import { ReactComponent as Image } from '../../../assets/icons/image.svg';
import manAvatar from '../../../assets/images/manAvatar.svg';
import monkeyAvatar from '../../../assets/images/monkeyAvatar.svg';
import womanAvatar from '../../../assets/images/womanAvatar.svg';
import birdAvatar from '../../../assets/images/birdAvatar.svg';
import textContent from '../../../common/textContent';
import { uiSetUserPreferencesAction } from '../../../store/actions/userActions';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    width: "100%",
    //height: "100%"
  },
  overlay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    padding: "1%",
    zIndex: 1000,
    backgroundColor: "rgba(53, 57, 69, 0.7)",
    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  overlayMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    padding: "5%",
    zIndex: 1000,
    backgroundColor: "rgba(53, 57, 69, 0.7)",
    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  hidden: {
    opacity: 0
  },
  bg: {
    zIndex: -1,
    display: 'none'
  },
  avatar: {
    backgroundColor: "blue",
    color: "blue",
  },
  dialogStyle: {
    // position: "absolute",
    // zIndex: 2,
    display: "flex",
    flexDirection: "column",
    padding: 10,
    position: "relative",
    borderRadius: 2,
    backgroundColor: "#ffffff",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.35)",
    //margin: 10,
    width: "100%",

    maxWidth: "600px",
    height: "100%",
    maxHeight: "100%",
    // padding: 0,
    // margin: 10,
    // maxWidth: "100%"
  },
  fitter: {
    flex: 20,
    display: 'flex'
  },
  backStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
    borderRadius: 20,
    transform: "translate(30%, -30%)",
    backgroundColor: "rgba(0,0,0, 0)",
    boxShadow: '0px 6px 12px #0000005A',

    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {

      boxShadow: '0px 0px 6px 3px rgb(165, 169, 178)'

    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: 10
  },
  buttonIconStyle: {
    width: 70,
    marginRight: 10,
  },
  buttonIconStyleMobile: {
    width: 50,
    marginRight: 10,
  },
  headerText: {
    fontSize: 20
  },
  buttonStyle: {
    height: 60,
    display: "flex",
    flex: 5
    //margin: "10px 0"
  },
  spacer: {
    flex: 1
  },
  contentChildStyle: {
    margin: 'auto',
    maxWidth: '100%'
  },
  invertedButtonStyle: {
    backgroundColor: '#353945!important',
    color: '#ffffff',
    '&hover': {
      backgroundColor: '#353945',
    },
    '&.MuiButton-iconSizeMedium > *:first-child': {
      // fontSize: 10,
      // },
    },
    textTransform: 'lowercase',
    margin: '0 auto 10px auto',
    width: '70%',
    maxWidth: 250,
    //height: 30
  },
  moreText: {
    flex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  moreTextInner: {
    flex: 0,
    fontSize: 30,
    textAlign: 'center'
  },
  moreTextInnerMobile: {
    flex: 0,
    fontSize: 20,
    textAlign: 'center'
  },
  invertedButtonTextStyle: {
    //fontSize: 15,
  },
  ///////////////////////////////////////////////////
  // INPUT
  mainImageContainer: {
    textAlign: 'center',
    paddingTop: 60,
    paddingBottom: 32,
  },
  mainImage: {
    width: 180,
    height: 210,
  },
  textCenter: {
    textAlign: 'center',
  },
  subTitleContainer: {
    maxWidth: 560,
    margin: '0 auto',
    // paddingLeft: 40,
    // paddingRight: 40,
  },
  tileContainer: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileContainerMobile: {
    height: '100%',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  /////
  subTitleStyleMobile: {
    fontSize: 18
  },
  subTitleStyle: {
    fontSize: 20
  },
  titleStyle: {
    fontSize: 24
  },
  titleStyleMobile: {
    fontSize: 20
  },
  tileStyle: {
    width: "100%",
    display: 'flex',
    height: "100%",
    flex: 5,
    margin: 0
  },
  tileStyleMobile: {
    width: "100%",
    display: 'flex',
    height: "100%",
    flex: 5,
    margin: 0

  },
  spacer3: {
    flex: 3
  },
  spacer2: {
    flex: 2
  },
  spacer5: {
    flex: 5
  },
  //spacerSettings:{}

  ///////////////////////////////////////////////////
  // OUTPUT

  ///////////////////////////////////////////////////
  // AVATAR
  tileColumn: {
    display: 'flex',
    flexBasis: '100%',
    flex: 1,
    justifyContent: 'center',
  },
  tileColumnMobile: {
    flex: 1,
    //flexWrap: 'wrap',
  },
  // buttonContainer: {
  //   textAlign: 'center',
  // },
  fixHeight: {
    height: 1000,
    //overflow: "scroll"
  }
});

function SimpleDialog(props) {
  const { overlay, dialogStyle, backStyle, header, buttonIconStyle, buttonIconStyleMobile, headerText,
    buttonStyle, hidden, bg, contentChildStyle, invertedButtonStyle, moreText, moreTextInner, moreTextInnerMobile,
    invertedButtonTextStyle, container, fixHeight, spacer, spacer2, spacer3, spacer5, fitter, overlayMobile } = useStyles();
  const { onClose,
    open, avatar, uiHideModal, show,
    setUserPreferences,
    uiTextToSpeechAutoplay,
  } = props;

  const {
    mainImageContainer,
    mainImage,
    textCenter,
    subTitleContainer,
    tileContainer,
    tileContainerMobile,
    buttonContainer,
    // avatar
    tileColumn,
    tileColumnMobile,
    subTitleContainerMobile,
    titleStyle,
    titleStyleMobile,
    subTitleStyleMobile,
    subTitleStyle,
    tileStyle,
    tileStyleMobile
  } = useStyles();

  const [inBg, setBg] = useState(show);
  useEffect(() => {
    if (!show) setTimeout(() => {
      setBg(true);
      setScreenState("HELP");
    }, 225); else setBg(false)
  }, [show]);

  const handleClose = () => {
    onClose(2);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const [selectedInputPreference, handleSelectInputPreference] = useState('write');
  const [selectedResultPreference, handleSelectResultPreference] = useState('text');
  const [selectedAvatar, handleSelectAvatar] = useState('');
  const [avatarImageSrc, setAvatarImageSrc] = useState('');

  const [helpTitle, setHelpTitle] = useState('Wie kann ich dir helfen?');

  useEffect(() => {
    importAvatarImageDynamically(selectedAvatar)
      .then((r) => setAvatarImageSrc(r.default))
      .catch((e) => console.warn(e));
  }, [selectedAvatar]);


  const matches = useMediaQuery('(max-width:690px)');
  useEffect(() => {
    importAvatarImageDynamically(avatar)
      .then((r) => {
        setAvatarImageSrc(r.default);
      })
      .catch((e) => console.warn(e));
  }, [avatar]);

  const [screenState, setScreenState] = useState("HELP"); // INPUT OUTPUT AVATAR
  let DialogComponent;
  if (screenState == "HELP")
    DialogComponent = (
      <>
        <div className={fitter}>
          <iframe
            width='100%'
            height='100%'
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            src={show ? "https://www.youtube.com/embed/5B7y9XeVEDY" : ''} className={contentChildStyle} frameBorder="0">

          </iframe>
        </div>
        <div className={spacer}></div>
        <ContentButton
          additionalStyle={buttonStyle}
          pos="R"
          startIcon={plus}
          onClick={() => {
            setScreenState("INPUT");
            setHelpTitle("Lieblings-Suche");
          }}
          buttonText={"Lieblings-Suche auswählen"}
        />
        <div className={spacer}></div>
        <ContentButton
          startIcon={plus}
          additionalStyle={buttonStyle}
          pos="R"
          onClick={() => {
            setScreenState("OUTPUT");
            setHelpTitle("Lieblings-Ergebnis");
          }}
          buttonText={"Lieblings-Ergebnis auswählen"}
        />
        <div className={spacer}></div>
        <ContentButton
          startIcon={plus}
          additionalStyle={buttonStyle}
          pos="R"
          onClick={() => {
            setScreenState("AVATAR");
            setHelpTitle(textContent.wizardScreens.selectAssistantWizardScreen.title);
          }}
          buttonText={"Aussehen von Tobi ändern"}
        />

        {/* <div className={moreText}><Typography className={matches?moreTextInnerMobile:moreTextInner}>Mehr Fragen?</Typography></div> */}
        <div className={spacer5} />
        <ContentButton
          additionalStyle={invertedButtonStyle}
          additionalTextStyle={invertedButtonTextStyle}
          pos="T"
          onClick={() => { window.location = 'https://frag-tobi.at/' }}
          buttonText={"www.frag-tobi.at"}
        />
      </>);
  else if (screenState == "INPUT") {

    DialogComponent = (<>
      {/* <div className={`${textCenter} ${subTitleContainer}`}>
            <Typography className={matches?titleStyleMobile: titleStyle}>
              {
                textContent.wizardScreens.selectDefaultInputTypeWizardScreen
                  .subtitle
              }
            </Typography>
          </div> */}
      <div className={`${textCenter} ${subTitleContainer}`}>
        <Typography className={`${matches ? subTitleStyleMobile : subTitleStyle}`}>
          {
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .subtitle
          }
        </Typography>
      </div>
      <div className={`${tileContainer}`}>
        <div className={spacer2}></div>
        <TileSVG
          SVG={Keyboard}
          imageAlt="schreiben"
          name="write"
          selected={selectedInputPreference}
          onClick={() => handleSelectInputPreference("write")}
          label={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .writeTileLabel
          }
          additionalCardStyle={matches ? tileStyleMobile : tileStyle}
        />
        <div className={spacer}></div>
        <TileSVG
          SVG={Say}
          imageAlt="sagen"
          name="audioRecord"
          selected={selectedInputPreference}
          onClick={() => handleSelectInputPreference("audioRecord")}
          label={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .sayTileLabel
          }
          additionalCardStyle={matches ? tileStyleMobile : tileStyle}
        />
        <div className={spacer}></div>
        <TileSVG
          SVG={Photo}
          imageAlt="foto zeigen"
          name="photo"
          selected={selectedInputPreference}
          onClick={() => handleSelectInputPreference("photo")}
          label={
            textContent.wizardScreens.selectDefaultInputTypeWizardScreen
              .photoTileLabel
          }
          additionalCardStyle={matches ? tileStyleMobile : tileStyle}
        />
        <div className={spacer2}></div>
        <div className={buttonContainer}>
          <SuccessButton
            additionalStyle={matches ? tileStyleMobile : tileStyle}
            buttonText={
              textContent.wizardScreens.selectDefaultInputTypeWizardScreen
                .successButtonText
            }
            onClick={() => {
              setScreenState("HELP");
              //uiHideModal();
              setHelpTitle('Wie kann ich dir helfen?');
              setUserPreferences({
                inputPreference: selectedInputPreference,
              });
            }}
          />
        </div>
      </div>

    </>);
  }
  else if (screenState == "OUTPUT") {
    DialogComponent = (<>
      {/* <div className={textCenter}>
          <Typography className={matches?titleStyleMobile: titleStyle}>
            {textContent.wizardScreens.selectDefaultResultTypeWizardScreen.title}
          </Typography>
        </div> */}
      <div className={`${textCenter} ${subTitleContainer}`}>
        <Typography className={`${matches ? subTitleStyleMobile : subTitleStyle}`}>
          {
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .subtitle
          }
        </Typography>
      </div>
      <div className={`${tileContainer} ${matches ? tileContainerMobile : ''}`}>
        <div className={spacer}></div>

        <TileSVG
          SVG={Video}
          imageAlt="tobi avatar"
          name="video"
          selected={selectedResultPreference}
          onClick={() => handleSelectResultPreference("video")}
          label={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .videoLabel
          }
          additionalCardStyle={matches ? tileStyleMobile : tileStyle}
        />
        <div className={spacer}></div>
        <TileSVG
          SVG={Text}
          imageAlt="tobi avatar"
          name="text"
          selected={selectedResultPreference}
          onClick={() => handleSelectResultPreference("text")}
          label={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .textLabel
          }
          additionalCardStyle={matches ? tileStyleMobile : tileStyle}
        />
        <div className={spacer}></div>
        <TileSVG
          SVG={Image}
          imageAlt="tobi avatar"
          name="image"
          selected={selectedResultPreference}
          onClick={() => handleSelectResultPreference("image")}
          label={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .imageLabel
          }
          additionalCardStyle={matches ? tileStyleMobile : tileStyle}
        />
        <div className={spacer3}></div>
        <div className={buttonContainer}>
          <SuccessButton
            buttonText={
              textContent.wizardScreens.selectDefaultResultTypeWizardScreen
                .successButtonText
            }
            onClick={() => {
              setScreenState("HELP");
              //uiHideModal();
              setHelpTitle('Wie kann ich dir helfen?');
              setUserPreferences({
                resultPreference: selectedResultPreference,
              });
            }}
          />
        </div>
      </div>
    </>);
  }
  else if (screenState == "AVATAR") {
    DialogComponent = (<>
      {/* <div className={textCenter}>
          <Typography className={matches?titleStyleMobile: titleStyle}>
            {textContent.wizardScreens.selectAssistantWizardScreen.title}
          </Typography>
        </div> */}
      <div className={`${textCenter} ${subTitleContainer}`}>
        <Typography className={`${matches ? subTitleStyleMobile : subTitleStyle}`}>
          {textContent.wizardScreens.selectAssistantWizardScreen.subtitle}
        </Typography>
      </div>
      <div className={tileContainer}>
        <div className={`${tileColumn} ${matches ? tileColumnMobile : ''}`}>
          <Tile
            image={manAvatar}
            imageAlt="tobi avatar"
            name="manAvatar"
            selected={selectedAvatar}
            onClick={() => handleSelectAvatar("manAvatar")}
          />
          <Tile
            image={womanAvatar}
            imageAlt="woman avatar"
            name="womanAvatar"
            selected={selectedAvatar}
            onClick={() => handleSelectAvatar("womanAvatar")}
          />
        </div>
        <div className={`${tileColumn} ${matches ? tileColumnMobile : ''}`}>
          <Tile
            image={birdAvatar}
            imageAlt="bird avatar"
            name="birdAvatar"
            selected={selectedAvatar}
            onClick={() => handleSelectAvatar("birdAvatar")}
          />
          <Tile
            image={monkeyAvatar}
            imageAlt="monkey avatar"
            name="monkeyAvatar"
            selected={selectedAvatar}
            onClick={() => handleSelectAvatar("monkeyAvatar")}
          />
        </div>
        <div className={spacer2}></div>
        <div className={buttonContainer}>
          <SuccessButton
            buttonText={
              textContent.wizardScreens.selectAssistantWizardScreen
                .successButtonText
            }
            onClick={() => {
              setScreenState("HELP");
              //uiHideModal();
              setHelpTitle('Wie kann ich dir helfen?');
              setUserPreferences({
                avatar: selectedAvatar,
                avatarImageSrc,
              });
            }}
          />
        </div>
      </div>
    </>);
  }

  const body = (
    <div className={`${matches ? overlayMobile : overlay} ${show ? '' : hidden} ${inBg ? bg : ''}`} onClick={() => { uiHideModal(); setHelpTitle('Wie kann ich dir helfen?'); }}>
      <div className={dialogStyle} onClick={(event) => event.stopPropagation()}>
        <img src={backButton} onClick={() => { uiHideModal(); setHelpTitle('Wie kann ich dir helfen?'); }} className={backStyle} />
        <div className={header}>
          <img src={avatarImageSrc} className={matches ? buttonIconStyleMobile : buttonIconStyle} alt={avatar} />


          <Typography className={headerText}>{helpTitle}</Typography>


        </div>
        {DialogComponent}

        {/* <HeaderLinkButton>www.frag-tobi.at</HeaderLinkButton> */}
      </div>
    </div>
  );
  const matchesHeight = useMediaQuery('(max-height:800px)');
  return matchesHeight && !matches ? (<div className={`${container} ${show ? '' : hidden} ${inBg ? bg : ''} ${fixHeight}`}>{body}</div>) : (<Div100vh className={`${container} ${show ? '' : hidden} ${inBg ? bg : ''}`}>{body}</Div100vh>);

}

const mapStateToProps = (state) => {
  const {
    userState: { avatar }, modalState: { show }
  } = state;
  return {
    avatar,
    show
  };
};

const mapDispatchToProps = {
  uiHideModal: uiHideModalAction,
  uiTextToSpeechAutoplay: uiTextToSpeechAutoplayRequestAction,
  setUserPreferences: uiSetUserPreferencesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleDialog);  