// modal
const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';

// app loaded
const UI_APP_LOADED = 'UI_APP_LOADED';

// loading action types
const UI_LOADING = 'UI_LOADING';
const LOADING_STARTED = 'LOADING_STARTED';
const LOADING_FINISHED = 'LOADING_FINISHED';

// user settings action types
const UI_SET_USER_PREFERENCES = 'UI_SET_USER_PREFERENCES';
const STORE_SET_USER_PREFERENCES = 'STORE_SET_USER_PREFERENCES';
const UI_APP_AUDIO_MUTE_STATE_CHANGE = 'UI_APP_AUDIO_MUTE_STATE_CHANGE';
const STORE_APP_AUDIO_MUTE_STATE_CHANGE = 'STORE_APP_AUDIO_MUTE_STATE_CHANGE';
const UI_SET_SEARCH_USER_PREFERENCE = 'UI_SET_SEARCH_USER_PREFERENCE';
const STORE_SEARCH_USER_PREFERENCE = 'STORE_SEARCH_USER_PREFERENCE';
const UI_SET_RESULT_USER_PREFERENCE = 'UI_SET_RESULT_USER_PREFERENCE';
const STORE_RESULT_USER_PREFERENCE = 'STORE_RESULT_USER_PREFERENCE';
const UI_RECORD_TOOLTIP_SHOWN = 'UI_RECORD_TOOLTIP_SHOWN';

// text to speech action types
const STORE_SET_REVISIT_FLAG = 'STORE_SET_REVISIT_FLAG';
const STORE_QUEUE_OR_EMPTY_BACKUP = 'STORE_QUEUE_OR_EMPTY_BACKUP';
const UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST = 'UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST';
const UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST =
  'UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST';
const UI_AUDIO_PLAY_FINISHED = 'UI_AUDIO_PLAY_FINISHED';
const TEXT_TO_SPEECH_REQUESTED = 'TEXT_TO_SPEECH_REQUESTED';
const TEXT_TO_SPEECH_SUCCEEDED = 'TEXT_TO_SPEECH_SUCCEEDED';
const TEXT_TO_SPEECH_FAILED = 'TEXT_TO_SPEECH_FAILED';
const STORE_TEXT_TO_SPEECH_URL = 'STORE_TEXT_TO_SPEECH_URL';
const STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE =
  'STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE';
const UI_WIZARD_STEP_CHANGED = 'UI_WIZARD_STEP_CHANGED';
const UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST = 'UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST';
const STORE_CLEAN_AUDIO_PLAY_QUEUE = 'STORE_CLEAN_AUDIO_PLAY_QUEUE';

// search action types
const UI_REQEST_RANDOM_WORDS = 'UI_REQEST_RANDOM_WORDS';	
const UI_SEARCH_SUGGESTION_REQUEST = 'UI_SEARCH_SUGGESTION_REQUEST';
const UI_IMG_RECOGNITION_REQUEST = 'UI_IMG_RECOGNITION_REQUEST';
const STORE_IMG_TAGS_TERM = 'STORE_IMG_TAGS_TERM';
const SEARCH_SUGGESTION_REQUEST = 'SEARCH_SUGGESTION_REQUEST';
const STORE_SEARCH_TERM = 'STORE_SEARCH_TERM';
const STORE_TEMP_SEARCH_TERM = 'STORE_TEMP_SEARCH_TERM';
const SEARCH_SUGGESTION_REQUESTED = 'SEARCH_SUGGESTION_REQUESTED';
const SEARCH_SUGGESTION_SUCCEEDED = 'SEARCH_SUGGESTION_SUCCEEDED';
const SEARCH_SUGGESTION_FAILED = 'SEARCH_SUGGESTION_FAILED';
const STORE_SEARCH_SUGGESTION_RESULT = 'STORE_SEARCH_SUGGESTION_RESULT';
const UI_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE_REQUEST =
  'UI_ADDITIONAL_SEARCH_KEYWORD_REQUEST';
const STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE =
  'STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE';
const UI_SEARCH_REQUEST = 'UI_SEARCH_REQUEST';
const SEARCH_REQUESTED = 'SEARCH_REQUESTED';
const SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED';
const SEARCH_FAILED = 'SEARCH_FAILED';
const STORE_TEXT_SEARCH_RESULT = 'STORE_TEXT_SEARCH_RESULT';
const STORE_VIDEO_SEARCH_RESULT = 'STORE_VIDEO_SEARCH_RESULT';
const STORE_PHOTO_SEARCH_RESULT = 'STORE_PHOTO_SEARCH_RESULT';
const UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST =
  'UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST';
const STORE_CLEAR_SEARCH_SUGGESTIONS = 'STORE_CLEAR_SEARCH_SUGGESTIONS';
const STORE_SET_SLIDER_INDEX = 'STORE_SET_SLIDER_INDEX';

// speech to text action types
const UI_SPEECH_TO_TEXT_REQUEST = 'UI_SPEECH_TO_TEXT_REQUEST';
const SPEECH_TO_TEXT_REQUESTED = 'SPEECH_TO_TEXT_REQUESTED';
const SPEECH_TO_TEXT_SUCCEEDED = 'SPEECH_TO_TEXT_SUCCEEDED';
const SPEECH_TO_TEXT_FAILED = 'SPEECH_TO_TEXT_FAILED';

export {
  STORE_SET_REVISIT_FLAG,
  STORE_QUEUE_OR_EMPTY_BACKUP,
  UI_REQEST_RANDOM_WORDS,
  SHOW_MODAL,
  HIDE_MODAL,
  STORE_IMG_TAGS_TERM,
  UI_IMG_RECOGNITION_REQUEST,
  UI_APP_LOADED,
  UI_LOADING,
  LOADING_STARTED,
  LOADING_FINISHED,
  UI_SET_USER_PREFERENCES,
  STORE_SET_USER_PREFERENCES,
  UI_APP_AUDIO_MUTE_STATE_CHANGE,
  STORE_APP_AUDIO_MUTE_STATE_CHANGE,
  UI_SET_SEARCH_USER_PREFERENCE,
  STORE_SEARCH_USER_PREFERENCE,
  UI_SET_RESULT_USER_PREFERENCE,
  STORE_RESULT_USER_PREFERENCE,
  UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST,
  UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST,
  UI_AUDIO_PLAY_FINISHED,
  TEXT_TO_SPEECH_REQUESTED,
  TEXT_TO_SPEECH_SUCCEEDED,
  TEXT_TO_SPEECH_FAILED,
  STORE_TEXT_TO_SPEECH_URL,
  STORE_REMOVE_PLAYED_AUDIO_FROM_QUEUE,
  UI_WIZARD_STEP_CHANGED,
  UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST,
  STORE_CLEAN_AUDIO_PLAY_QUEUE,
  UI_SEARCH_SUGGESTION_REQUEST,
  SEARCH_SUGGESTION_REQUEST,
  STORE_SEARCH_TERM,
  STORE_TEMP_SEARCH_TERM,
  SEARCH_SUGGESTION_REQUESTED,
  SEARCH_SUGGESTION_SUCCEEDED,
  SEARCH_SUGGESTION_FAILED,
  STORE_SEARCH_SUGGESTION_RESULT,
  UI_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE_REQUEST,
  STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE,
  UI_SEARCH_REQUEST,
  SEARCH_REQUESTED,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED,
  STORE_TEXT_SEARCH_RESULT,
  STORE_VIDEO_SEARCH_RESULT,
  STORE_PHOTO_SEARCH_RESULT,
  UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST,
  STORE_CLEAR_SEARCH_SUGGESTIONS,
  UI_SPEECH_TO_TEXT_REQUEST,
  SPEECH_TO_TEXT_REQUESTED,
  SPEECH_TO_TEXT_SUCCEEDED,
  SPEECH_TO_TEXT_FAILED,
  UI_RECORD_TOOLTIP_SHOWN,
  STORE_SET_SLIDER_INDEX
};
