import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  footerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom:0
  },
  footerContainerMultiple: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  padding: {
    padding: '20px 64px',
  },
  paddingMobile: {
    padding: '10px 34px',
  }
});

const Footer = ({ children }) => {
  const { footerContainer, footerContainerMultiple, paddingMobile, padding } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');

  const filteredChildren = children && children.filter((element) => !!element);

  return (
    <div
      className={`${
        filteredChildren && filteredChildren.length > 1
          ? footerContainerMultiple
          : footerContainer
      } ${matches?paddingMobile:padding}` }
    >
      {children}
    </div>
  );
};

export default Footer;
