import {
  UI_SET_USER_PREFERENCES,
  STORE_SET_USER_PREFERENCES,
  UI_APP_AUDIO_MUTE_STATE_CHANGE,
  STORE_APP_AUDIO_MUTE_STATE_CHANGE,
  UI_SET_SEARCH_USER_PREFERENCE,
  STORE_SEARCH_USER_PREFERENCE,
  UI_SET_RESULT_USER_PREFERENCE,
  STORE_RESULT_USER_PREFERENCE,
  UI_RECORD_TOOLTIP_SHOWN
} from './actionTypes';

const uiSetUserPreferencesAction = (payload) => ({
  type: UI_SET_USER_PREFERENCES,
  payload,
});

const storeSetUserPreferencesAction = (payload) => ({
  type: STORE_SET_USER_PREFERENCES,
  payload,
});

const uiAppAudioMuteStateChangeAction = () => ({
  type: UI_APP_AUDIO_MUTE_STATE_CHANGE,
});

const storeAppAudioMuteStateChangeAction = () => ({
  type: STORE_APP_AUDIO_MUTE_STATE_CHANGE,
});

const uiSetSearchUserPreferenceAction = (payload) => ({
  type: UI_SET_SEARCH_USER_PREFERENCE,
  payload,
});

const storeSearchUserPreferenceAction = (payload) => ({
  type: STORE_SEARCH_USER_PREFERENCE,
  payload,
});

const uiSetResultUserPreferenceAction = (payload) => ({
  type: UI_SET_RESULT_USER_PREFERENCE,
  payload,
});

const storeResultUserPreferenceAction = (payload) => ({
  type: STORE_RESULT_USER_PREFERENCE,
  payload,
});

const uiRecordToolTipShownAction = (payload) => ({
  type: UI_RECORD_TOOLTIP_SHOWN
})


export {
  uiSetUserPreferencesAction,
  storeSetUserPreferencesAction,
  uiAppAudioMuteStateChangeAction,
  storeAppAudioMuteStateChangeAction,
  uiSetSearchUserPreferenceAction,
  storeSearchUserPreferenceAction,
  uiSetResultUserPreferenceAction,
  storeResultUserPreferenceAction,
  uiRecordToolTipShownAction
};
