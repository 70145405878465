import axios from 'axios';
import {
  ocpapimsubKey,
  issueTokenURL,
  speechToTextUrl,
} from '../common/constants';

const speechToTextService = (wav) => {
  return axios
    .post(
      issueTokenURL,
      {},
      {
        headers: {
          'Ocp-Apim-Subscription-Key': ocpapimsubKey,
        },
      }
    )
    .then((result) => {
      return axios
        .post(speechToTextUrl, wav, {
          method: 'POST',
          headers: {
            //'Content-Type' : 'audio/ogg; codecs=opus',
            'Content-Type': 'audio/wav; codecs=audio/pcm; samplerate=16000',
            Authorization: `Bearer ${result.data}`,
          },
        })
        .then((response) => {
          return response;
        });
    })
    .catch((err) => console.warn(err));
};

export default speechToTextService;
