import axios from 'axios';
import {
  suggestURL,
  suggestSubscriptionKey
} from '../common/constants';

const suggestService = (term) => {
  return axios
    .get(`${suggestURL}?q=${term.trim()}&mkt=de-AT`, {
      headers: {
        'Ocp-Apim-Subscription-Key': suggestSubscriptionKey,
      },
    })
    .then((result) => {
      return result;
    })
    .catch((err) => console.warn(err));
};

export default suggestService;