import {
  takeLatest,
  takeEvery,
  call,
  put,
  select
} from 'redux-saga/effects';
import {
  UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST,
  UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST,
  UI_AUDIO_PLAY_FINISHED,
  UI_WIZARD_STEP_CHANGED,
  UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST,
} from '../actions/actionTypes';
import {
  textToSpeechService
} from '../../services';
import {
  textToSpeechRequestedAction,
  storeTextToSpeechAction,
  textToSpeechSucceededAction,
  textToSpeechFailedAction,
  storeRemovePlayedAudioFromQueueAction,
  storeCleanAudioPlayQueueAction,
  // uiTextToSpeechAutoplayRequestAction
} from '../actions/textToSpeachActions';

function* processPlaylist(action) {
  const {
    payload,
    type
  } = action;
  if (Array.isArray(payload))
    for (let i = 0; i < payload.length; i++)
      yield call(getSpeech, {
        payload: payload[i],
        type,
      });
  else yield call(getSpeech, action);
}

function* getSpeech(action) {
  const {
    payload,
    type
  } = action;
  try {
    const muted = yield select((store)=>store.userState.appMuted);
    if(muted){
      return;
    }
      
    yield put(textToSpeechRequestedAction());
    const url = yield call(
      textToSpeechService,
      'Male',
      'de-AT-Michael',
      payload
    );
    if (type === UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST) {
      yield put(storeCleanAudioPlayQueueAction());
    }
    yield put(
      storeTextToSpeechAction({
        url,
        text: payload,
      })
    );
    yield put(textToSpeechSucceededAction());
  } catch (error) {
    yield put(textToSpeechFailedAction());
    console.warn(error);
  }
}

function* removePlayedAudioFromQueue() {
  yield put(storeRemovePlayedAudioFromQueueAction());
}

function* cleanAudioPlayQueue() {
  yield put(storeCleanAudioPlayQueueAction());
}

function* textToSpeechSaga() {
  yield takeEvery(
    [
      UI_TEXT_TO_SPEECH_AUTOPLAY_REQUEST,
      UI_TEXT_TO_SPEECH_EXPLICIT_PLAY_REQUEST,
    ],
    processPlaylist
  );
  yield takeEvery(UI_AUDIO_PLAY_FINISHED, removePlayedAudioFromQueue);
  yield takeLatest(
    [UI_WIZARD_STEP_CHANGED, UI_CLEAN_AUDIO_PLAY_QUEUE_REQUEST],
    cleanAudioPlayQueue
  );
}

export default textToSpeechSaga;