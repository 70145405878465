import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  textFieldStyle: {
    width: '100%',
    margin: '44px 0',
    
  },
  textFieldSize: {
    fontSize: '20px'
  },
  textFieldSizeMobile: {
    fontSize: '18px'
  }
});

const InputTextField = ({ placeholder, value, onChange }) => {
  const { textFieldStyle, textFieldSize, textFieldSizeMobile } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <TextField
    InputProps={{
      classes: {
        input: matches?textFieldSizeMobile:textFieldSize,
      },
    }}
      fontSize={matches?'18pt':'22pt'}
      value={value}
      className={textFieldStyle}
      placeholder={placeholder}
      onChange={handleOnChange}
      autoFocus
    />
  );
};

export default InputTextField;
