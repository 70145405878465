import { takeLatest, call, put, select } from 'redux-saga/effects';
import { UI_SPEECH_TO_TEXT_REQUEST } from '../actions/actionTypes';
import { speechToTextService } from '../../services';
import {
  searchSuggestionRequest,
  storeTempSearchTermAction,
} from '../actions/searchActions';
import {
  speechToTextRequestedAction,
  speechToTextSucceededAction,
  speechToTextFailedAction,
} from '../actions/speechToTextActions';
import {
  loadingStartedAction,
  loadingFinishedAction,
} from '../actions/loadingActions';

const getAdditionalKeywordState = (state) =>
  state.searchState.additionalKeywordRequested;
const getSearchTerm = (state) => state.searchState.searchTerm;

function* getText(action) {
  const { payload } = action;
  const additionalSearchKeyword = yield select(getAdditionalKeywordState);
  const searchTerm = yield select(getSearchTerm);
  try {
    yield put(loadingStartedAction());
    yield put(speechToTextRequestedAction());

    const responseObj = yield call(speechToTextService, payload);
    const text =
      responseObj &&
      responseObj.data &&
      responseObj.data.NBest &&
      responseObj.data.NBest[0] &&
      responseObj.data.NBest[0].Lexical
        ? responseObj.data.NBest[0].Lexical
        : '';

    if (additionalSearchKeyword) {
      yield put(storeTempSearchTermAction(`${searchTerm} ${text}`));
    } else {
      yield put(searchSuggestionRequest(text));
      yield put(storeTempSearchTermAction(text));
    }

    yield put(speechToTextSucceededAction());
    yield put(loadingFinishedAction());
  } catch (e) {
    console.warn(e);
    yield put(speechToTextFailedAction());
    yield put(loadingFinishedAction());
  }
}

function* speechToTextSaga() {
  yield takeLatest(UI_SPEECH_TO_TEXT_REQUEST, getText);
}

export default speechToTextSaga;
