import React from 'react';
import { makeStyles, useMediaQuery } from "@material-ui/core";

const useStyle = makeStyles({
    pageMarginStyle: {
        position: 'relative',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        height: '100%',
        marginLeft: 64,
        marginRight: 64
    },
    pageMarginStyleMobile: {
        position: 'relative',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        height: '100%',
        marginLeft: 34,
        marginRight: 34
    },
    middleContainer: {
        // position: 'absolute',
        // top: 0,
        // bottom: 0,
        // left: 0,
        // right: 0
      },
});
const PageMargin = ({children})=>{
    const matches = useMediaQuery('(max-width:690px)');
    const {pageMarginStyle, pageMarginStyleMobile, middleContainer} = useStyle();
    return (<div className={`${matches?pageMarginStyleMobile: pageMarginStyle}`}>
        {children}</div>);
}

export default PageMargin;