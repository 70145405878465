import React, { useCallback } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PageMargin from '../pageMargin';

const useStyles = makeStyles({
  titleStyle: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 20
  },
  titleStyleMobile: {
    marginTop: 20,
    marginBottom: 5,
    textAlign: 'center',
    fontSize: 20
  },
  imageContainer: {
    flex: '1 1',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    minHeight: 0,
    //minHeight: '300px',
    objectFit: 'cover',
  },
  image: {
    //maxHeight: '250px',
    minHeight: 0,
    maxWidth: '95%',
    maxHeight: '95%',
    width: 'auto',
    margin: '10px auto',
    '&:hover':{
      boxShadow: '0px 0px 5px 5px rgba(188,188,209,1)'
    }
  },
  imageMobile: {
    //maxHeight: '140px',
    minHeight: 0,
    maxWidth: '95%',
    maxHeight: '95%',
    width: 'auto',
    margin: '10px auto',
    '&:hover':{
      boxShadow: '0px 0px 5px 5px rgba(188,188,209,1)'
    }
  },
  flexer:{
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    display: 'flex'
  },
  embedLink:{
    height: '100%',
    width: '100%',
    display: 'block',
    minHeight:0
  }
});

const ResultContentPhoto = ({ name, src, contentUrl, webSearchUrl }) => {
  const matches = useMediaQuery('(max-width:690px)');
  const history = useHistory();
  const playbackPictureClb= useCallback(()=>history.push('/content-preview',{url: contentUrl, type: "PICTURE", title:name, webSearchUrl}));
  const { titleStyle, imageContainer, image, titleStyleMobile, imageMobile, flexer, embedLink } = useStyles();
  return (
    <PageMargin>
      <div className={flexer}>
      <div>
        <Typography className={matches? titleStyleMobile:titleStyle} variant="h5">
          {name}
        </Typography>
      </div>
      <div className={imageContainer} onClick={playbackPictureClb}> 
        <a href={src} className={embedLink} title="Bild angucken" onClick={(e)=>e.preventDefault()}><img src={src} alt={src} className={matches?imageMobile:image} /></a>
        
      </div>
      </div>
    </PageMargin>
  );
};

export default ResultContentPhoto;
