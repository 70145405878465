import React from 'react';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  button: {
    position: 'relative',
    display: 'block',
    width: '100%',
    borderRadius: 3,
    boxShadow: '0px 6px 12px #0000005A',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#353945',
      color: '#ffffff',
      fill: '#ffffff',
      
    },
    '&:hover svg': {
      //backgroundColor: '#353945',
      fill: '#ffffff',
      
    },
    // '&:hover img': {
    //   //backgroundColor: '#353945',
    //   fill: '#ffffff',
    // },
    '& span': {
      display: 'inline',
    },
  },
  buttonMargin: {
    // marginTop: 34,
    // marginBottom: 34,
  },
  buttonMarginMobile: {
    // marginTop: 14,
    // marginBottom: 14,
  },
  buttonBackground: {
    backgroundColor: '#ffffff',
    color: '#353945',
  },
  buttonBackgroundReading: {
    backgroundColor: '#353945',
    color: '#ffffff',
    '& svg': {
      //backgroundColor: '#353945',
      fill: '#ffffff',
      
    }
  },
  buttonIconStyle: {
    height: 45,
    width: 45,
    marginRight: 10, // only desktop
    //flex: 0,
    fill: "#353945",
    
  },
  buttonIconStyleMobile: {
    height: 30,
    width: 30,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5, // only desktop
    //flex: 0,
    fill: "#353945",
    
  },
  textStyle: {
    textTransform: 'none',
    textAlign: "start",
    flex: 1,
    fontSize: 22,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  textStyleMobile: {
    textTransform: 'none',
    textAlign: "center",
    flex: 1,
    fontSize: 20,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonTitleH: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  buttonTitleV: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  }
});

const ContentButton = ({
  additionalStyle,
  buttonText,
  startIcon,
  onClick,
  autoplayAudioText,
  type,
  pos,
  additionalTextStyle,
  additionalBg,
}) => {
  const {
    button,
    buttonIconStyle,
    buttonIconStyleMobile,
    textStyle,
    textStyleMobile,
    buttonBackgroundReading,
    buttonBackground,
    buttonMargin,
    buttonMarginMobile,
    buttonTitleH,
    buttonTitleV
  } = useStyles();
  const StartIcon=startIcon;
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <Button
      size={matches?'small': ''}
      type={type}
      className={`${button} ${
        buttonText === autoplayAudioText
          ? buttonBackgroundReading
          : (additionalBg || buttonBackground)
        } ${additionalStyle} ${
          matches? buttonMarginMobile:buttonMargin
        }`}
      variant="contained"
      
      onClick={onClick}
    >
      <div className={(pos=="B" || pos=="T") ?buttonTitleV:buttonTitleH}>
      {(StartIcon && (pos == "L" || pos == "T" || !pos))? <StartIcon className={`${
            matches ? buttonIconStyleMobile : buttonIconStyle
            }`} /> : null}
      <Typography variant="button" className={`${matches ? textStyleMobile : textStyle} ${additionalTextStyle}`}>
        {buttonText}
      </Typography>
      {(StartIcon && (pos == "R" || pos == "B"))? <StartIcon className={`${
            matches ? buttonIconStyleMobile : buttonIconStyle
            }`} /> : null}
      </div>
    </Button>
  );
};

const Search = ()=>{

}

const mapStateToProps = (state) => {
  const autoplayAudioText = state.textToSpeechState.autoplayAudioURLs[0]
    ? state.textToSpeechState.autoplayAudioURLs[0].text
    : '';

  return {
    autoplayAudioText,
  };
};

export default connect(mapStateToProps)(ContentButton);
