import { UI_LOADING, LOADING_STARTED, LOADING_FINISHED } from './actionTypes';

const uiLoadingAction = () => ({
  type: UI_LOADING,
});

const loadingStartedAction = () => ({
  type: LOADING_STARTED,
});

const loadingFinishedAction = () => ({
  type: LOADING_FINISHED,
});

export { uiLoadingAction, loadingStartedAction, loadingFinishedAction };
