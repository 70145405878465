import { SHOW_MODAL, HIDE_MODAL } from './actionTypes';

const uiShowModalAction = () => ({
  type: SHOW_MODAL,
});

const uiHideModalAction = () => ({
    type: HIDE_MODAL,
  });


export { uiHideModalAction, uiShowModalAction };
