import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { AudioRecordButton, ContentModal } from '../../simpleComponents';
import { uiSpeechToTextRequestAction } from '../../../store/actions/speechToTextActions';
import AdditionalSearchConfirmation from '../additionalSearchConfirmation';
import textContent from '../../../common/textContent';
import {ReactComponent as plus} from '../../../assets/icons/plus.svg';
import {
  uiSearchSuggestionRequestAction,
  uiAdditionalSearchKeywordStateChangeRequestAction,
  storeTempSearchTermAction,
} from '../../../store/actions/searchActions';
import { uiCleanAudioPlayQueueRequestAction, uiTextToSpeechAutoplayRequestAction, storeTextToSpeechAction } from '../../../store/actions/textToSpeachActions';
import AudioRecorderPolyfill from 'audio-recorder-polyfill';
import RecordRTC from 'recordrtc';
import PageMargin from '../../simpleComponents/pageMargin';
import touchApp from '../../../assets/icons/touch_app.svg';
import { uiRecordToolTipShownAction } from '../../../store/actions/userActions';
//import {saveAs} from 'file-saver';

const useStyles = makeStyles({
  soundWave: {
    display: 'none',
  },
  searchTermContainer: {
    position: 'absolute',
    width: '100%',
    border: '2px solid #E0E0E0',
    marginTop: 34,
    textAlign: 'center',
  },
  searchTermText: {
    fontSize: 20,
  },
  searchTermTextMobile:{
    fontSize: 18,
  },
  toolTipPicStyle:{
    position: 'absolute',
    //bottom: -25,
    width: 250,
    left: '50%',
    top: '25%'
  },
  toolTipPicStyleMobile:{
    position: 'absolute',
    //bottom: -25,
    width: 150,
    left: '50%',
    top: '25%'
  },
  toolTipTextStyle: {
    fontSize: 22,
    clear: 'right',
    fontStyle: 'italic',
    marginBottom: '5%'
  },
  toolTipTextStyleMobile: {
    fontSize: 20,
    clear: 'right',
    fontStyle: 'italic',
    marginBottom: 10
  },
  recordToolTipStyle: {
    position: 'absolute',
    bottom: 0,
    margin: 'auto 20px'
  }
});
let recorder;
const AudioRecorder = ({
  searchTerm,
  tempSearchTerm,
  additionalKeywordRequested,
  uiSpeechToTextRequest,
  uiSearchSuggestionRequest,
  uiAdditionalSearchKeywordStateChangeRequest,
  uiCleanAudioPlayQueueRequest,
  uiTextToSpeechAutoplayRequest,
  storeTextToSpeech,
  //recordToolTip,
  uiRecordToolTipShown,
  storeTempSearchTerm,
  setLabel
}) => {
  const [recording, setRecording] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newSearchTerm, setNewSearchTerm] = useState(searchTerm);
  const [recordToolTip, setRecordToolTip] = useState(true);

  useEffect(() => {
    if (additionalKeywordRequested && tempSearchTerm) {
      setNewSearchTerm(tempSearchTerm);
    }
    if (!additionalKeywordRequested) {
      setNewSearchTerm(searchTerm);
    }
    setLabel('Das habe ich verstanden. Ist das richtig?');
    //setTimeout(()=>uiTextToSpeechAutoplayRequest(['Das habe ich verstanden. Ist das richtig?']));
  }, [searchTerm, tempSearchTerm, additionalKeywordRequested]);

  const [transient, setTransient] = useState(true);

  useEffect(() => {
    if (transient && (showConfirmation || additionalKeywordRequested))
      setTransient(false);
  }, [searchTerm, tempSearchTerm]);

  useEffect(() => {
    if (!transient) {
      uiTextToSpeechAutoplayRequest([additionalKeywordRequested ? tempSearchTerm : searchTerm,
      textContent.components.textSearch.confirmationSuccessButton,
      textContent.components.textSearch.confirmationFailedButton,
      textContent.components.textSearch.confirmationContentButton]);
    }
  }, [transient]);

  const { soundWave, searchTermContainer, searchTermText, 
    searchTermTextMobile, toolTipPicStyle, toolTipTextStyle, recordToolTipStyle, toolTipPicStyleMobile, toolTipTextStyleMobile } = useStyles();
  

  
  const handleRecordClick = useCallback(() => {
    //tooltip logic
    setRecordToolTip(false);
    // if(recordToolTip)
    //   uiRecordToolTipShown();
    if (!recording){
      uiCleanAudioPlayQueueRequest();
      //start recording
      window.navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        // if chrome, use recordrtc for wav output
        if(navigator.userAgent.indexOf("Chrome") > -1 || navigator.userAgent.indexOf("Firefox") > -1){
          recorder = RecordRTC(stream, {
              //type: 'audio',
              //mimeType: 'audio/wav',
              bitsPerSecond:16000,
              recorderType: RecordRTC.StereoAudioRecorder
          });
          recorder.startRecording();
        }
        // other browsers
        else{
          if(!window.MediaRecorder) // polyfill
            window.MediaRecorder = AudioRecorderPolyfill;
          recorder = new window.MediaRecorder(stream);
          recorder.addEventListener('dataavailable', e => {
            //const url = URL.createObjectURL(e.data)
            //saveAs(e.data, 'alma.wav');
            uiSpeechToTextRequest(e.data)
            setShowConfirmation(true);
            //storeTextToSpeech({url, payload: 'blablabla'});
          });
          recorder.start();
        }
      })
    }
    else{
      //stop recording
      if(navigator.userAgent.indexOf("Chrome") > -1 || navigator.userAgent.indexOf("Firefox") > -1){
        recorder.stopRecording(function() {
          let blob = recorder.getBlob();
          uiSpeechToTextRequest(blob)
          setShowConfirmation(true);
        });
      }
      else{
        recorder.stop();
      }
    }
    setRecording(!recording);
  }, [recording]);

  const handleRecordStopped = useCallback((recordedBlob) => {
    //console.log(recordedBlob);
    //setTimeout(()=>{
      // stop recording
      


      
      //setTimeout(()=>{uiSpeechToTextRequest(recordedBlob.blob);
      setShowConfirmation(true);
    //},0);
  }, [storeTextToSpeech]);

  const handleAdditionalSearchConfirmationAddKeyword = useCallback(() => {
    uiAdditionalSearchKeywordStateChangeRequest();
    setShowConfirmation(false);
  });

  const handleAdditionalSearchConfirmationSuccess = useCallback(() => {
    uiSearchSuggestionRequest(newSearchTerm);
    if (additionalKeywordRequested) {
      uiAdditionalSearchKeywordStateChangeRequest();
    }
  });

  const handleAdditionalSearchConfirmationFailed = useCallback(() => {
    if (additionalKeywordRequested) {
      //uiAdditionalSearchKeywordStateChangeRequest();
      // const prevTerm = searchTerm.split(' ').slice(0,-1).join(' ');
      // console.log(prevTerm);
      // console.log(searchTerm);
      setNewSearchTerm(searchTerm);
      // storeTempSearchTerm(prevTerm);
    }
    setShowConfirmation(false);
    setTransient(true);

    // workaround for repeat bug
    //history.replace("/search");

  });
  const matches = useMediaQuery('(max-width:690px)');
  
  return (
    <PageMargin>
      {additionalKeywordRequested ? (
        <div className={searchTermContainer}>
          <Typography className={matches?searchTermTextMobile:searchTermText}>{newSearchTerm}</Typography>
        </div>
      ) : null}
      {/* <ReactMic
        record={recording}
        className={soundWave}
        onStop={handleRecordStopped}
      /> */}
      <AudioRecordButton onClick={handleRecordClick} recording={recording} />
      {recordToolTip?
      (<><img src={touchApp} onClick={handleRecordClick} className={matches?toolTipPicStyleMobile:toolTipPicStyle}/>
      <div className={recordToolTipStyle}>
        <Typography className={matches?toolTipTextStyleMobile:toolTipTextStyle}>
        Kleiner Tipp: Drücke auf den Knopf und leg los! Wenn du fertig bist, drücke noch einmal den Knopf.
        </Typography>
      </div></>):(<></>)
      }
      {showConfirmation ? (
       
        <ContentModal>
          <AdditionalSearchConfirmation
            newSearchTerm={transient ? '' : newSearchTerm}
            successButtonLink="/search-suggestion"
            successButtonOnClick={handleAdditionalSearchConfirmationSuccess}
            successButtonText={
              textContent.components.textSearch.confirmationSuccessButton
            }
            failedButtonOnClick={handleAdditionalSearchConfirmationFailed}
            failedButtonText={
              textContent.components.textSearch.confirmationFailedButton
            }
            addKeywordButtonOnClick={
              handleAdditionalSearchConfirmationAddKeyword
            }
            addKeywordButtonText={
              textContent.components.textSearch.confirmationContentButton
            }
            addKeywordButtonIcon={plus}
          />
        </ContentModal>
        
      ) : null}
    </PageMargin>
  );
};

const mapStateToProps = (state) => {
  const {
    searchState: { tempSearchTerm },
    userState: {recordToolTip}
  } = state;
  return {
    tempSearchTerm,
    recordToolTip
  };
};

const mapDispatchToProps = {
  storeTextToSpeech: storeTextToSpeechAction,
  uiSpeechToTextRequest: uiSpeechToTextRequestAction,
  uiSearchSuggestionRequest: uiSearchSuggestionRequestAction,
  uiAdditionalSearchKeywordStateChangeRequest: uiAdditionalSearchKeywordStateChangeRequestAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction,
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiRecordToolTipShown: uiRecordToolTipShownAction,
  storeTempSearchTerm: storeTempSearchTermAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioRecorder);
