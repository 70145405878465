import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { uiSpeechToTextRequestAction } from '../../../store/actions/speechToTextActions';
import textContent from '../../../common/textContent';
//import plus from '../../../assets/icons/plus.svg';
import {
  uiSearchSuggestionRequestAction,
  uiAdditionalSearchKeywordStateChangeRequestAction,
  uiSearchRequestAction,
  uiImgRecognitionAction,
  storeSearchTermAction,
} from '../../../store/actions/searchActions';
import { uiCleanAudioPlayQueueRequestAction, uiTextToSpeechAutoplayRequestAction, uiTextToSpeechExplicitPlayRequestAction } from '../../../store/actions/textToSpeachActions';
import {ReactComponent as PhotoCameraIcon} from '../../../assets/icons/camera_alt.svg';
import {ReactComponent as PermMediaIcon} from '../../../assets/icons/collections.svg';
import {ReactComponent as arrow} from '../../../assets/icons/arrow.svg';
import { PlayAudioButton, ContentButton, ContentLinkButton, FailedButton, SuccessButton } from '../../simpleComponents';
import { useCallback } from 'react';
import PageMargin from '../../simpleComponents/pageMargin';



const useStyles = makeStyles({
  soundWave: {
    display: 'none',
  },
  searchTermContainer: {
    border: '2px solid #E0E0E0',
    marginTop: 64,
    textAlign: 'center',
  },
  searchTermText: {
    fontSize: 50,
    color: '#000000',
  },
  spacer: {
    flex:5
  },
  spacer2: {
    flex:2
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    
    alignItems: 'center',
  },
  suggestionItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 2
  },
  additionalContentLinkStyle: {
    width: '100%',
    height: '100%',
    marginRight: 10,
    display: 'flex'
    // marginTop: 36,
    // marginBottom: 36,
  },
  inputOverlay: {
    position: 'absolute',
    visibility: 'hidden',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // width: '100%',
    // cursor: 'pointer'
  },
  inputContainer: {
    //flexGrow: 'none',
    //alignSelf: 'flex-start',
    // position: 'relative',
    //marginTop: 25,
    flex: 4
  },
  confirmPicStyle: {
    flex: 1,
    minHeight: 0,
    height: '100%',
    margin: 'auto',
    display: 'block',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center'
    //maxHeight:300
  },
  confirmPicStyleMobile: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    minHeight: 0,
    margin: 'auto',
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
    //maxHeight:100
  },
  mainButtonContainer: {
    
    //paddingTop: 25,
    //flexBasis: '300px',
    flex: 1,
    height: '100%',
    width: '100%',
    //height: '300px',
    paddingTop: 5,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  beforSearchHeight:{
    height: '100%',

  },
  confirmHeight:{
    height: '100%',
    
  },
  
  leftAlign: {
    textAlign: 'left'
  }
  // innerContainer: {
  //   position: 'absolute',
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0
  // },
});
const BEFORE_SEARCH = 'BEFORE_SEARCH';
const CONFIRM_PICTURE = 'CONFIRM_PICTURE';
const SELECT_OBJECT = 'SELECT_OBJECT';

const ImageCapture = ({
  searchTerm,
  tempSearchTerm,
  additionalKeywordRequested,
  uiSpeechToTextRequest,
  uiSearchSuggestionRequest,
  uiAdditionalSearchKeywordStateChangeRequest,
  uiCleanAudioPlayQueueRequest,
  uiTextToSpeechAutoplayRequest,
  uiSearchRequest,
  uiTextToSpeechExplicitPlayRequest,
  uiImgRecognition,
  setLabel,
  storeSearchTerm
}) => {
  const {soundWave,searchTermContainer, searchTermText, buttonContainer, 
    suggestionItemContainer, additionalContentLinkStyle, inputOverlay, 
    inputContainer, confirmPicStyle, confirmPicStyleMobile, mainButtonContainer,
  beforSearchHeight,confirmHeight, spacer, spacer2, leftAlign}=useStyles();
  
  const matches = useMediaQuery('(max-width:690px)');
  const [searchState, setSearchState] = useState(BEFORE_SEARCH);

  const takePhotoRef = useRef();
  const selectPhotoRef = useRef();
  const imgRef = useRef();

  // shallowEqual must be used
  const currentImgTags = useSelector((store)=>store.searchState.currentImgTags, shallowEqual);

  const handleSuggestionClick = (suggestion) => {
    if(additionalKeywordRequested){
      uiSearchRequest(searchTerm + suggestion);
      storeSearchTerm(searchTerm + suggestion);
    }
    else{
      uiSearchRequest(suggestion);
      storeSearchTerm(suggestion);
    }
    
  };

  const handleSuggestionToAudio = (suggestion) => {
    uiTextToSpeechExplicitPlayRequest(suggestion);
  }; 

  const handleSubmit=(event) => {
    
    event.preventDefault();
    //roff.current=selectPhotoRef.current?.files[0];
    imgRef.current=takePhotoRef.current.files[0];
    setSearchState(CONFIRM_PICTURE);


}
  const handleSubmitPickPhoto = (event) => {
    event.preventDefault();
    imgRef.current=selectPhotoRef.current.files[0];
    setSearchState(CONFIRM_PICTURE);
    //selectPhotoRef.current.files[0]
      
  }

  const failedButtonOnClick = () => {setSearchState(BEFORE_SEARCH);}
  const successButtonOnClick = () => {
    //do magic here
    uiImgRecognition(imgRef.current);
    setSearchState(SELECT_OBJECT);
  }

  useEffect(() => {
    if(searchState===BEFORE_SEARCH)
      // uiTextToSpeechAutoplayRequest([additionalKeywordRequested ? tempSearchTerm : searchTerm]);
      // ugly before refactoring 
      setTimeout(()=>searchState===BEFORE_SEARCH&&uiTextToSpeechAutoplayRequest(["Foto machen", "Foto hochladen"]),1000);
    else if(searchState===CONFIRM_PICTURE){
      uiCleanAudioPlayQueueRequest();
      uiTextToSpeechAutoplayRequest(["Ist gut!", "Nochmal!"]);
    }
    else if(searchState===SELECT_OBJECT && currentImgTags.length!==0){
      uiCleanAudioPlayQueueRequest();
      setLabel("Das habe ich erkannt. Was passt dir?");
      uiTextToSpeechAutoplayRequest(["Das habe ich erkannt. Was passt dir?",...(currentImgTags.map(e=>e.translatedTag))]);
    }
  }, [searchState,currentImgTags]);

  var body;


  if(searchState===BEFORE_SEARCH)
    body = (
        <PageMargin>
      <div className={`${mainButtonContainer} ${beforSearchHeight}`}>
      {additionalKeywordRequested ? (
        <div className={searchTermContainer}>
          <Typography className={searchTermText}>{searchTerm}</Typography>
        </div>
      ) : null}
      <div className={spacer}></div>
      <div className={inputContainer}>
      <input type="file" className={inputOverlay} ref={takePhotoRef} onChange={(event) => {
            handleSubmit(event);
        }} />
        
        <ContentButton
          onClick={()=>takePhotoRef.current.click()}
          buttonText={"Foto machen"}
          startIcon={PhotoCameraIcon}
          additionalStyle={additionalContentLinkStyle}
        />
        
      </div>
      <div className={spacer}></div>
       <div className={inputContainer}>
        <input id="myFileInput" type="file" accept="image/*" className={inputOverlay} ref={selectPhotoRef} onChange={(event) => {
            handleSubmitPickPhoto(event);
        }} />
        <ContentButton
          onClick={()=>selectPhotoRef.current.click()}
          buttonText={"Foto hochladen"}
          startIcon={PermMediaIcon}
          additionalStyle={additionalContentLinkStyle}
          //disabled={true}
        />
       </div>
       <div className={spacer}></div>
       <div className={spacer}></div>
       <div className={spacer}></div>
       <div className={spacer}></div>
       
      </div>
      </PageMargin>
    );
  else if(searchState===CONFIRM_PICTURE)
      body = (<PageMargin>
      <div className={mainButtonContainer}>
        <div className={matches?confirmPicStyleMobile:confirmPicStyle}>
      <img height="100%" src={URL.createObjectURL(imgRef.current)}/>
      </div>
        <div className={buttonContainer}>
          <FailedButton
            onClick={failedButtonOnClick}
            buttonText={"Nochmal!"}
          />
          <SuccessButton
            onClick={successButtonOnClick}
            buttonText={"Ist gut!"}
          />
      </div>
      </div>
    </PageMargin>);
  else if(searchState===SELECT_OBJECT)
      body = (<PageMargin>
        <div className={`${mainButtonContainer} ${confirmHeight}`}>
        <div className={spacer2}></div>
        {currentImgTags && currentImgTags.map((e) => {
          const item = e.translatedTag;
          return(
            <>
            <div
              className={suggestionItemContainer}
              key={`${item.replace(' ', '')}-cont`}
            >
              <ContentLinkButton
                key={`${item.replace(' ', '')}-link`}
                buttonText={item}
                linkTo="/search-result"
                onClick={() => handleSuggestionClick(item)}
                startIcon={arrow}
                additionalStyle={additionalContentLinkStyle}
                additionalTextStyle={leftAlign}
              />
              <PlayAudioButton
                key={`${item}-audio`}
                onClick={() => handleSuggestionToAudio(item)}
              />
            </div>
            <div className={spacer2}></div>
            </>
          )}) || (<></>)}
          </div>
    </PageMargin>);
  return (
    <>
      {body}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    searchState: { tempSearchTerm},
  } = state;
  return {
    tempSearchTerm
  };
};

const mapDispatchToProps = {
  uiSpeechToTextRequest: uiSpeechToTextRequestAction,
  uiSearchSuggestionRequest: uiSearchSuggestionRequestAction,
  uiAdditionalSearchKeywordStateChangeRequest: uiAdditionalSearchKeywordStateChangeRequestAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction,
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
  uiSearchRequest: uiSearchRequestAction,
  uiTextToSpeechExplicitPlayRequest: uiTextToSpeechExplicitPlayRequestAction,
  uiImgRecognition: uiImgRecognitionAction,
  storeSearchTerm: storeSearchTermAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCapture);
