import { takeLatest, put, call } from 'redux-saga/effects';
import {
  UI_SEARCH_SUGGESTION_REQUEST,
  UI_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE_REQUEST,
  UI_SEARCH_REQUEST,
  SEARCH_SUGGESTION_REQUEST,
  UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST,
  UI_IMG_RECOGNITION_REQUEST,
} from '../actions/actionTypes';
import {
  storeSearchTermAction,
  searchSuggestionRequestedAction,
  searchSuggestionSucceeded,
  storeSearchSuggestionResult,
  searchSuggestionFailed,
  storeAdditionalSearchKeywordStateChangeAction,
  searchRequestedAction,
  searchRequestSucceededAction,
  searchRequestFailedAction,
  storeTextSearchResultAction,
  storeVideoSearchResultAction,
  storePhotoSearchResultAction,
  storeClearSearchSuggestionsAction,
  storeImgTagsAction,
  storeSetSliderIndexAction
} from '../actions/searchActions';
import {
  loadingStartedAction,
  loadingFinishedAction,
} from '../actions/loadingActions';
import { suggestService, searchService, searchByFileService, translatorService } from '../../services';
import imgResizer from '../../services/imgResizerService';

function* getSearchSuggestion(action) {
  const { payload } = action;
  yield put(storeSearchTermAction(payload));
  try {
    yield put(loadingStartedAction());
    yield put(searchSuggestionRequestedAction());
    const searchResult = yield call(suggestService, payload);
    const suggestions = searchResult.data.suggestionGroups[0].searchSuggestions.map(
      (item) => item.displayText
    );
    yield put(searchSuggestionSucceeded());
    yield put(storeSearchSuggestionResult(suggestions));
    yield put(loadingFinishedAction());
  } catch (e) {
    yield put(searchSuggestionFailed());
    yield put(loadingFinishedAction());
    console.warn(e);
  }
}

function* setAdditionalKeywordRequest() {
  yield put(storeAdditionalSearchKeywordStateChangeAction());
}

function* search(action) {
  const { payload } = action;
  try {
    yield put(searchRequestedAction());
    yield put(loadingStartedAction());
    yield put(storeSetSliderIndexAction({type: 'text', value: 0}));
    yield put(storeSetSliderIndexAction({type: 'video', value: 0}));
    yield put(storeSetSliderIndexAction({type: 'photo', value: 0}));
    const textResponse = yield call(searchService, { term: payload, type: '' });
    const textResultData = textResponse.data.webPages.value.map(
      (responseItem) => ({
        displayUrl: responseItem.url,
        id: responseItem.id,
        name: responseItem.name,
        snippet: responseItem.snippet,
        webSearchUrl: textResponse.data.webPages.webSearchUrl
      })
    );
    const textResult = {
      type: 'text',
      data: textResultData,
    };
    yield put(storeTextSearchResultAction(textResult));
  } catch (e) {
    yield put(storeTextSearchResultAction([]));
    // yield put(searchRequestFailedAction());
    // yield put(loadingFinishedAction());
    console.warn(e);
  }
  try {
    const videoResponse = yield call(searchService, {
      term: payload,
      type: 'videos',
    });
    const videoResultData = videoResponse.data.value.filter(e=>e.embedHtml).map((responseItem) => ({
      id: responseItem.videoId,
      contentUrl: responseItem.contentUrl,
      embedHtml: responseItem.embedHtml.match(/src=['"](.*?)['"]/)[1],
      name: responseItem.name,
      description: responseItem.description,
      uri: /[^=]*$/.exec(responseItem.contentUrl)[0],
      thumbnailUrl: responseItem.thumbnailUrl,
      webSearchUrl: responseItem.webSearchUrl
    }));
    const videoResult = {
      type: 'video',
      data: videoResultData,
    };
    yield put(storeVideoSearchResultAction(videoResult));
  } catch (e) {
    yield put(storeVideoSearchResultAction([]));
    // yield put(searchRequestFailedAction());
    // yield put(loadingFinishedAction());
    console.warn(e);
  }
  try{
    const photoResponse = yield call(searchService, {
      term: payload,
      type: 'images',
    });
    const photoResultData = photoResponse.data.value.map((responseItem) => ({
      id: responseItem.imageId,
      name: responseItem.name,
      contentUrl: responseItem.contentUrl,
      thumbnailUrl: responseItem.thumbnailUrl,
      webSearchUrl: responseItem.webSearchUrl
    }));
    const photoResult = {
      type: 'photo',
      data: photoResultData,
    };
    yield put(storePhotoSearchResultAction(photoResult));

  } catch (e) {
    yield put(storePhotoSearchResultAction([]));
    // yield put(searchRequestFailedAction());
    // yield put(loadingFinishedAction());
    console.warn(e);
  }
  try{
    yield put(searchRequestSucceededAction());
    yield put(loadingFinishedAction());
  } catch (e) {
    yield put(searchRequestFailedAction());
    yield put(loadingFinishedAction());
    console.warn(e);
  }
}

function* imageRecognizer(action){
  yield put(storeImgTagsAction([]));
  yield put(loadingStartedAction());
  try{
    const resizedImg = yield call(imgResizer, action.payload);
    const objList = yield call(searchByFileService, resizedImg);
    console.log(objList);
    let currentTags =[];
    for( const tag of objList.data.tags)
      currentTags.push({
        tag: tag.name,
        confidence: tag.confidence,
        translatedTag: (yield call(translatorService, tag.name)).data[0].translations[0].text
      }); 
    console.log(currentTags)
    yield put(storeImgTagsAction(currentTags.slice(0,4)))
    yield put(loadingFinishedAction());
  }
  catch(e){
    console.log(e);
    yield put(loadingFinishedAction());
  }
  
  // store suggestion results
}

function* cleanSearchSuggestions() {
  yield put(storeClearSearchSuggestionsAction());
}

function* searchSaga() {
  yield takeLatest(
    [UI_SEARCH_SUGGESTION_REQUEST, SEARCH_SUGGESTION_REQUEST],
    getSearchSuggestion
  );
  yield takeLatest(
    UI_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE_REQUEST,
    setAdditionalKeywordRequest
  );
  yield takeLatest(UI_SEARCH_REQUEST, search);
  yield takeLatest(UI_IMG_RECOGNITION_REQUEST, imageRecognizer);
  yield takeLatest(UI_CLEAR_SEARCH_SUGGESTIONS_REQUEST, cleanSearchSuggestions);
}

export default searchSaga;
