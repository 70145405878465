import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  SuccessButton,
  TileSVG,
} from '../../components/simpleComponents';
import { ReactComponent as Video } from '../../assets/icons/video.svg';
import { ReactComponent as Text } from '../../assets/icons/text.svg';
import { ReactComponent as Image } from '../../assets/icons/image.svg';
import textContent from '../../common/textContent';
import {
  uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChangedAction,
} from '../../store/actions/textToSpeachActions';

const useStyles = makeStyles({
  mainImageContainer: {
    textAlign: 'center',
    paddingTop: 60,
    paddingBottom: 32,
  },
  mainImage: {
    width: 180,
    height: 210,
  },
  textCenter: {
    textAlign: 'center',
  },
  subTitleContainer: {
    maxWidth: 560,
    margin: '0 auto',
    paddingLeft: 40,
    paddingRight: 40,
  },
  tileContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tileContainerMobile: {
    flexWrap: 'wrap',
  },
  buttonContainer: {
    textAlign: 'center',
  },
});

const SelectDefaultResultTypeWizardScreen = ({
  selectedAvatarSrc,
  selectedResultPreference,
  setSelectedResultPreference,
  submit,
  uiTextToSpeechAutoplay,
}) => {
  const {
    mainImageContainer,
    mainImage,
    textCenter,
    subTitleContainer,
    tileContainer,
    tileContainerMobile,
    buttonContainer,
  } = useStyles();

  const handleSelectResultPreference = (event) => {
    setSelectedResultPreference(event.currentTarget.getAttribute('name'));
  };

  const matches = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    uiTextToSpeechAutoplay(
      textContent.wizardScreens.selectDefaultResultTypeWizardScreen.title
    );
    uiTextToSpeechAutoplay(
      textContent.wizardScreens.selectDefaultResultTypeWizardScreen.subtitle
    );
  }, [uiTextToSpeechAutoplay]);

  return (
    <Container>
      <div className={mainImageContainer}>
        <img className={mainImage} src={selectedAvatarSrc} alt="avatar" />
      </div>
      <div className={textCenter}>
        <Typography variant="h3">
          {textContent.wizardScreens.selectDefaultResultTypeWizardScreen.title}
        </Typography>
      </div>
      <div className={`${textCenter} ${subTitleContainer}`}>
        <Typography variant="h5">
          {
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .subtitle
          }
        </Typography>
      </div>
      <div className={`${tileContainer} ${matches ? tileContainerMobile : ''}`}>
        <TileSVG
          SVG={Video}
          imageAlt="tobi avatar"
          name="video"
          selected={selectedResultPreference}
          onClick={handleSelectResultPreference}
          label={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .videoLabel
          }
        />
        <TileSVG
          SVG={Text}
          imageAlt="tobi avatar"
          name="text"
          selected={selectedResultPreference}
          onClick={handleSelectResultPreference}
          label={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .textLabel
          }
        />
        <TileSVG
          SVG={Image}
          imageAlt="tobi avatar"
          name="image"
          selected={selectedResultPreference}
          onClick={handleSelectResultPreference}
          label={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .imageLabel
          }
        />
      </div>
      <div className={buttonContainer}>
        <SuccessButton
          buttonText={
            textContent.wizardScreens.selectDefaultResultTypeWizardScreen
              .successButtonText
          }
          onClick={submit}
        />
      </div>
    </Container>
  );
};

const mapDispatchToProps = {
  uiTextToSpeechAutoplay: uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChanged: uiWizardStepChangedAction,
};

export default connect(
  null,
  mapDispatchToProps
)(SelectDefaultResultTypeWizardScreen);
