import {
  STORE_SET_USER_PREFERENCES,
  STORE_APP_AUDIO_MUTE_STATE_CHANGE,
  STORE_SEARCH_USER_PREFERENCE,
  STORE_RESULT_USER_PREFERENCE,
  UI_RECORD_TOOLTIP_SHOWN
} from '../actions/actionTypes';

const userInitState = {
  avatar: '',
  avatarVoice: '',
  avatarImageSrc: '',
  avatarGender: '',
  inputPreference: 'write',
  resultPreference: 'text',
  firstTimeUser: false,
  onBoardingCompleted: false,
  appMuted: false,
  recordToolTip: true,
};

const userReducer = (userState = userInitState, action) => {
  switch (action.type) {
    case UI_RECORD_TOOLTIP_SHOWN: {
      return {
        ...userState,
        recordToolTip: false
      };
    }
    case STORE_SET_USER_PREFERENCES: {
      const { payload } = action;
      return {
        ...userState,
        ...payload,
      };
    }
    case STORE_APP_AUDIO_MUTE_STATE_CHANGE: {
      return {
        ...userState,
        appMuted: !userState.appMuted,
      };
    }
    case STORE_SEARCH_USER_PREFERENCE: {
      const { payload } = action;
      return {
        ...userState,
        inputPreference: payload,
      };
    }
    case STORE_RESULT_USER_PREFERENCE: {
      const { payload } = action;
      return {
        ...userState,
        resultPreference: payload,
      };
    }
    default:
      return userState;
  }
};

export default userReducer;
