import React from 'react';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    
    position: 'relative',
    display: 'block',
    borderRadius: 3,
    boxShadow: '0px 6px 12px #0000005A',
    '&:hover': {
      backgroundColor: '#353945',
      color: '#ffffff',
      fill: '#ffffff',
      
    },
    '&:hover svg': {
      //backgroundColor: '#353945',
      fill: '#ffffff',
      
    },
    textAlign: 'center',
    '& span': {
      display: 'inline',
    },
    padding: '6px 16px'
  },
  buttonMargin: {
    // marginTop: 34,
    // marginBottom: 34,
  },
  buttonMarginMobile: {
    // marginTop: 7,
    // marginBottom: 7,
  },
  buttonBackground: {
    backgroundColor: '#ffffff',
    color: '#353945',
  },
  buttonBackgroundReading: {
    backgroundColor: '#353945',
    color: '#ffffff',
    '& svg': {
      //backgroundColor: '#353945',
      fill: '#ffffff',
      
    },
  },
  buttonIconStyle: {
    height: 45,
    width: 45,
    marginRight: 10, // only desktop
    //flex: 0,
    fill: "#353945",
    
  },
  buttonIconStyleMobile: {
    height: 30,
    width: 30,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5, // only desktop
    //flex: 0,
    fill: "#353945",
    
    //fill: '#ffffff'
  },
  textStyle: {
    textTransform: 'none',
    textAlign: "center",
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 22

  },
  textStyleMobile: {
    textTransform: 'none',
    textAlign: "center",
    flex: 1,
    fontSize: 20,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonTitleH: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  buttonTitleV: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  },
});

const ContentLinkButton = ({
  autoplayAudioText,
  additionalStyle,
  additionalTextStyle,
  linkTo,
  buttonText,
  startIcon,
  onClick,
  disabled,
  type,
  pos
}) => {
  const {
    button,
    buttonIconStyle,
    buttonBackground,
    buttonBackgroundReading,
    buttonIconStyleMobile,
    textStyle,
    textStyleMobile,
    buttonMargin,
    buttonMarginMobile,
    buttonTitleH,
    buttonTitleV
  } = useStyles();

  const matches = useMediaQuery('(max-width:600px)');
  const StartIcon= startIcon;
  return (
    <Button
    size={matches?'small': ''}
      type={type}
      className={`${button} ${
        buttonText === autoplayAudioText
          ? buttonBackgroundReading
          : buttonBackground
        } ${additionalStyle} ${
          matches? buttonMarginMobile:buttonMargin
        }`}
      disabled={disabled}
      variant="contained"
      component={RouterLink}
      to={linkTo || ''}
      // startIcon={
      //   <img
      //     src={startIcon}
      //     className={`${buttonIconStyle} ${
      //       matches ? buttonIconStyleMobile : ''
      //       }`}
      //     //fill={"#ffffff"}
      //     //alt="search"
      //   />
      // }
      onClick={onClick}
    >
      <div className={(pos=="B" || pos=="T") ?buttonTitleV:buttonTitleH}>
      {(pos == "L" || pos == "T" || !pos)? <StartIcon className={`${
            matches ? buttonIconStyleMobile : buttonIconStyle
            }`} /> : null}
      <Typography variant="button" className={`${matches ? textStyleMobile : textStyle} ${additionalTextStyle}`}>
        {buttonText}
      </Typography>
      {(pos == "R" || pos == "B")? <StartIcon className={`${
            matches ? buttonIconStyleMobile : buttonIconStyle
            }`} /> : null}
      </div>
    </Button>
  );
};

const mapStateToProps = (state) => {
  const autoplayAudioText = state.textToSpeechState.autoplayAudioURLs[0]
    ? state.textToSpeechState.autoplayAudioURLs[0].text
    : '';

  return {
    autoplayAudioText,
  };
};

export default connect(mapStateToProps)(ContentLinkButton);
