import { takeLatest, put, select } from 'redux-saga/effects';
import { UI_APP_LOADED } from '../actions/actionTypes';
import { storeAdditionalSearchKeywordStateChangeAction } from '../actions/searchActions';

const getAdditionalKeywordState = (state) =>
  state.searchState.additionalKeywordRequested;

function* setAdditionalKeywordStateToDefault() {
  const additionalSearchKeyword = yield select(getAdditionalKeywordState);
  if (!additionalSearchKeyword) {
    return;
  }

  yield put(storeAdditionalSearchKeywordStateChangeAction());
}

function* appSaga() {
  yield takeLatest(UI_APP_LOADED, setAdditionalKeywordStateToDefault);
}

export default appSaga;
