import axios from 'axios';
import { translatorToken, translatorUrl } from '../common/constants';

const translateService = (word) => {
  const body = [
    {
      Text: word,
    },
  ];

  return axios.post(translatorUrl, body, {
    headers: {
      'Ocp-Apim-Subscription-Key': translatorToken,
      'Ocp-Apim-Subscription-Region': 'westeurope'      // IF RESOURCE IS NOT IN GLOBAL AZURE REGION
    },
  });
};

export default translateService;
