import { all } from 'redux-saga/effects';
import textToSpeechSaga from './textToSpeechSaga';
import userSaga from './userSaga';
import searchSaga from './searchSaga';
import speechToTextSaga from './speechToTextSaga';
import appSaga from './appSaga';

function* rootSaga() {
  yield all([
    textToSpeechSaga(),
    userSaga(),
    searchSaga(),
    speechToTextSaga(),
    appSaga(),
  ]);
}

export default rootSaga;
