import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, useMediaQuery } from '@material-ui/core';
import { ReactComponent as Mic } from '../../../assets/icons/mic.svg';
import textContent from '../../../common/textContent';
//import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';

const useStyles = makeStyles(theme => ({
  buttonContainerStyle: {
    position: "relative",
    width: "100%",
    margin: 'auto',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  
  buttonStyle: {
    position: "absolute",
    // top: "50%",
    // transform: "translate(-50%, -50%)",
    //margin: 'auto',
    borderRadius: '50%',
    backgroundColor: '#E64252',
    '&:hover': {
      backgroundColor: '#E64252',
    },
    color: '#ffffff',
    textTransform: 'lowercase',
    boxShadow: '0 6px 12px #00000026  '
  },
  nonanimatedItem: {
    width: 232,
    height: 232,
  },
  nonanimatedItemMobile: {
    width: 132,
    height: 132,
  },
  animatedItem: {
    animation: `$myEffect 3000ms infinite ${theme.transitions.easing.easeInOut}`
  },
  animatedItemMobile: {
    animation: `$myEffectMobile 3000ms infinite ${theme.transitions.easing.easeInOut}`
  },
  "@keyframes myEffect": {
    "0%": {
      width: 232,
      height: 232,
      opacity: 1
    },
    "50%": {
      width: 280,
      height: 280,
      opacity: 0.8
    },
    "100%": {
      width: 232,
      height: 232,
      opacity: 1
    }
  },
  "@keyframes myEffectMobile": {
    "0%": {
      width: 116,
      height: 116,
      opacity: 1
    },
    "50%": {
      width: 140,
      height: 140,
      opacity: 0.8
    },
    "100%": {
      width: 116,
      height: 116,
      opacity: 1
    }
  },
  micStyle: {
    
  },
  micStyleMobile:{
    width: 30,
    height: 30,
  }
}));


const AudioRecordButton = ({ onClick, recording }) => {
  const matches = useMediaQuery('(max-width:690px)');
  const { buttonContainerStyle, buttonStyle, animatedItem, nonanimatedItem,
     animatedItemMobile, nonanimatedItemMobile, containerSize, containerSizeMobile,
     micStyle,micStyleMobile } = useStyles();
  return (
    <div className={`${buttonContainerStyle} ${matches?containerSizeMobile:containerSize}`}>
      <Button className={`${buttonStyle} ${recording ? (matches?animatedItemMobile:animatedItem) : (matches?nonanimatedItemMobile:nonanimatedItem)}`} onClick={onClick}>
        {recording ? (
          textContent.components.audioRecordButton.buttonText
        ) : (
            <Mic className={matches?micStyleMobile:micStyle}/>
          )}
      </Button>
    </div>
  );
};

export default AudioRecordButton;
