import React, { useCallback } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  ResultContentText,
  ResultContentVideo,
  ResultContentPhoto,
  SliderCustomNextArrow,
  SliderCustomPrevArrow,
} from '../../simpleComponents';
import { makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { storeSetSliderIndexAction } from '../../../store/actions/searchActions';
import { useRef } from 'react';

const useStyles = makeStyles({
  sliderStyle: {
    minHeight: "100%",
    height: '100%',
    '& .slick-list':{
      height: '100%',
    },
    '& .slick-track':{
      height: '100%',
    },
    '& .slick-slide > div':{
      height: '100%',
    }
  },
  
  notFound:{
    marginTop: "10%",
    textAlign: "center",
    padding: "0 5%",
    fontSize: 20
  }
});

const ResultContent = ({ contentData, loading, videoIndex, textIndex, photoIndex, storeSetSliderIndex }) => {
  const settings = {
    //centerMode: true
    //adaptiveHeight: true,
    dots: false,
    //lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    beforeChange: (current, next) => storeSetSliderIndex({type: contentData.type, value: next}),
    nextArrow: <SliderCustomNextArrow />,
    prevArrow: <SliderCustomPrevArrow />,
  };
  const { sliderStyle, notFound}= useStyles();

  const slider = useCallback((slider)=>{
    if(!slider)
      return;
    console.log(slider.current)
    
    switch (contentData.type){
      case 'text': slider.slickGoTo(textIndex, true); break;
      case 'video': slider.slickGoTo(videoIndex, true); break;
      case 'photo': slider.slickGoTo(photoIndex, true); break;
    }
    
  });
  

  return (
    !loading &&<Slider ref={slider} {...settings  } className={sliderStyle}>
      {contentData.type === 'text' &&
      contentData.data &&
      contentData.data.length
        ? contentData.data.map((item) => (
            <ResultContentText
              key={item.id}
              name={item.name}
              snippet={item.snippet}
              url={item.displayUrl}
              webSearchUrl={item.webSearchUrl}
            />
          ))
        : contentData.type === 'text' && <Typography className={notFound}>Es tut mir leid, ich habe nichts gefunden.</Typography>}
      {contentData.type === 'video' &&
      contentData.data &&
      contentData.data.length
        ? contentData.data.map((item) => (
            <ResultContentVideo
              key={item.id}
              name={item.name}
              description={item.description}
              uri={item.embedHtml}
              thumbnailUrl={item.thumbnailUrl}
              webSearchUrl={item.webSearchUrl}
            />
          ))
        : contentData.type === 'video' && <Typography className={notFound}>Es tut mir leid, ich habe nichts gefunden.</Typography>}
      {contentData.type === 'photo' &&
      contentData.data &&
      contentData.data.length
        ? contentData.data.map((item) => (
            <ResultContentPhoto
              key={item.id}
              name={item.name}
              src={item.thumbnailUrl}
              contentUrl={item.contentUrl}
              webSearchUrl={item.webSearchUrl}
            />
          ))
        : contentData.type === 'photo' && <Typography className={notFound}>Es tut mir leid, ich habe nichts gefunden.</Typography>}
    </Slider>
  );
};

const mapStateToProps = ({ loadingState, searchState }) => {
  const { loading } = loadingState;
  const { videoIndex, textIndex, photoIndex } = searchState;
  return {
    loading,
    videoIndex, textIndex, photoIndex
  };
};

const mapDispatchToProps = {
  storeSetSliderIndex: storeSetSliderIndexAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultContent);
