import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { Container, LinkButton } from '../../components/simpleComponents';
import { AudioAutoplay } from '../../components/advancedComponents';
import {ReactComponent as TobiAvatar} from '../../assets/images/manAvatar.svg';
import lebensHilfeLogo from '../../assets/images/lebensHilfeLogo.png';
import textContent from '../../common/textContent';
import Div100vh from 'react-div-100vh';
import { PageTemplate } from '../../components/advancedComponents';

const useStyles = makeStyles({
  imageContainer: {
    flex:6,
    textAlign: 'center',
    //maxHeight: 300,
    height: '100%'
    // marginTop: 74,
    //marginBottom: 20,
    
  },
  imageContainerMobile: {
    flex:6,
    textAlign: 'center',
    //maxHeight: 300,
    height: '180px'
    // marginTop: 74,
    //marginBottom: 20,
    
  },
  avatarStyle:{
    // width: '30%',
    maxHeight: '25vh',
    height: '100%'
  },
  textCenter2: {
    flex: 2,
    textAlign: 'center',
  },
  textCenter: {
    flex: 1,
    textAlign: 'center',
  },
  buttonContainer: {
    flex:2,
    textAlign: 'center',
  },
  button: {
    flex: 1,
    width: '40%',
    height: '100%',
    fontSize: '40px !important;',
    // minHeight: 70,
    // maxWidth: 250,
    // height: '20%',
  },
  buttonMobile: {
    flex: 1,
    
    width: '40%',
    height: '100%'
    // minHeight: 70,
    // maxWidth: 250,
    // height: '20%',
  },
  fitScreen:{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  logoContainer: {
    flex: 0.5
  },
  spacer:{
    flex: 1
  },
  spacer2:{
    flex: 2
  },
  spacer3:{
    flex: 3
  },
  spacer4:{
    flex: 4
  },
});

const HomePage = ({ onBoardingCompleted }) => {
  const {
    imageContainer,
    imageContainerMobile,
    textCenter,
    buttonContainer,
    button,
    buttonMobile,
    logoContainer,
    fitScreen,
    avatarStyle,
    spacer,
    spacer2,
    spacer3,
    spacer4,
    textCenter2
  } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  return (
    <PageTemplate onlyContainer={true}>
      
        <div className={fitScreen}>
          <div className={spacer}></div>
        <div className={matches?imageContainerMobile:imageContainer}>
          <TobiAvatar className={avatarStyle}/>
        </div>
        <div className={spacer}></div>
        <Typography className={textCenter2} variant="h1">
          {textContent.pages.home.title}
        </Typography>
        <Typography className={textCenter} variant="h4">
          {textContent.pages.home.subtitle}
        </Typography>
        <div className={spacer3}></div>
        <div className={buttonContainer}>
          <LinkButton
            additionalStyle={matches?buttonMobile: button}
            buttonText={textContent.pages.home.linkButton}
            linkTo={`${onBoardingCompleted ? '/start' : '/onboarding'}`}
          />
        </div>
        <div className={spacer3}></div>
        <div className={`${textCenter} ${logoContainer}`}>
          <Typography variant="subtitle1">
            {textContent.pages.home.logoText}
          </Typography>
          <img src={lebensHilfeLogo} alt="lebensHilfeLogo" />
        </div>
        </div>
     
      <AudioAutoplay />
    </PageTemplate> 
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { onBoardingCompleted },
  } = state;
  return {
    onBoardingCompleted,
  };
};

export default connect(mapStateToProps, null)(HomePage);
