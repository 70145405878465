import React from 'react';
import { Fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  mainSize: {
    height: "100%"
  }
});

const FadeEffect = ({ fadeIn, timeout, children }) => {
  const {mainSize} = useStyles();
  return (
    <Fade className={mainSize}  in={fadeIn} timeout={timeout || 500}>
      <div>{children}</div>
    </Fade>
  );
};

export default FadeEffect;
