import axios from 'axios';
import { searchURL, webAPI, searchKey } from '../common/constants';

const searchService = ({ term, type }) => {
  let searchType = '';
  if (type.length > 0) {
    searchType = `${type}/`;
  }
  return axios.get(
    `${
      searchURL + searchType
    }search?q=${term}&customconfig=${webAPI}&mkt=de-AT`,
    {
      headers: {
        'Ocp-Apim-Subscription-Key': searchKey,
      },
    }
  );
};

export default searchService;
