import {
  UI_SPEECH_TO_TEXT_REQUEST,
  SPEECH_TO_TEXT_REQUESTED,
  SPEECH_TO_TEXT_SUCCEEDED,
  SPEECH_TO_TEXT_FAILED,
} from './actionTypes';

const uiSpeechToTextRequestAction = (payload) => ({
  type: UI_SPEECH_TO_TEXT_REQUEST,
  payload,
});

const speechToTextRequestedAction = () => ({
  type: SPEECH_TO_TEXT_REQUESTED,
});

const speechToTextSucceededAction = () => ({
  type: SPEECH_TO_TEXT_SUCCEEDED,
});

const speechToTextFailedAction = () => ({
  type: SPEECH_TO_TEXT_FAILED,
});

export {
  uiSpeechToTextRequestAction,
  speechToTextRequestedAction,
  speechToTextSucceededAction,
  speechToTextFailedAction,
};
