import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Header,
  HeaderLinkButton,
  Container,
  HeaderMuteButton,
  Footer,
  AvatarButton,
  FooterBackButton,
} from '../../simpleComponents';
import { useMediaQuery } from '@material-ui/core';
import Div100vh from 'react-div-100vh'
import Lining from '../../simpleComponents/lining';
import { detect } from 'detect-browser';
const browser = detect();


const useStyles = makeStyles({
  containerAdditionalStyle: {
    maxWidth: '700px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
  },
  containerAdditionalStyleMobile: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
  },
  content: {
    minHeight: 0,
    position: 'relative',
    flexGrow: 1,
    flex: 1,
    //padding: '0 34px 34px 34px',
    backgroundColor: '#FFFFFF',
  },
  pageBody: {
    position: 'absolute',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden'
  },
  fixHeight: {
    height: 1000,
    //overflow: "scroll"
  }
  // w, h, trans, rot, grad, overf
});

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}


const PageTemplate = ({
  headerTextContent,
  avatarFooterButtonText,
  showOnlyAvatarFooterButton,
  avatarFooterButtonOnClick,
  onlyContainer,
  children,
}) => {
  const { containerAdditionalStyle, content, containerAdditionalStyleMobile, pageBody, fixHeight } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  const matchesHeight = useMediaQuery('(max-height:800px)');
  const isNotApple = (browser && browser.name !== 'safari' && browser.name !== 'ios');
  const windowSize = useWindowSize();
  useEffect(()=>{
    //auto zoom for smaller screens
    if(matchesHeight && !matches && isNotApple)
     if(window.innerHeight>=600)
      document.body.style.zoom=window.innerHeight/10 + "%";
    else
      document.body.style.zoom="60%";
    else
    document.body.style.zoom='';
    }, [windowSize, matches ,matchesHeight]);

  const body = (<Container additionalStyle={matches?containerAdditionalStyleMobile:containerAdditionalStyle}>
    {onlyContainer?children:(<><Header headerText={headerTextContent}>
      <HeaderLinkButton />
      <HeaderMuteButton />
    </Header>
    <div className={content}>{children}</div>
    <Footer>
      <AvatarButton
        avatarButtonText={avatarFooterButtonText}
        avatarButtonOnClick={avatarFooterButtonOnClick}
      />
      {!showOnlyAvatarFooterButton && <FooterBackButton />}
    </Footer></>)}
  </Container>);
  
  return matchesHeight && !matches?(<div className={fixHeight}>{body}</div>):(<Div100vh className={pageBody}>{body}</Div100vh>);
};

export default PageTemplate;
