import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  containerStyle: {
    
    // height: '100vh',
    // height: '-webkit-fill-available',
    height: '100%',
    backgroundColor: '#F2F2F2',
  },
});

const Container = ({ additionalStyle, children }) => {
  const { containerStyle } = useStyles();
  return (
    <div className={`${containerStyle} ${additionalStyle}`}>{children}</div>
  );
};

export default Container;
