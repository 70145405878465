import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  Container,
  SuccessButton,
  Tile,
} from '../../components/simpleComponents';
import textContent from '../../common/textContent';
import {
  uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChangedAction,
  storeCleanAudioPlayQueueAction,
} from '../../store/actions/textToSpeachActions';
import { uiAppAudioMuteStateChangeAction } from '../../store/actions/userActions';
import ReactPlayer from 'react-player';
import backButton from '../../assets/icons/back_button.png';

const useStyles = makeStyles({
  imageContainer: {
    flex:1,
    textAlign: 'center',
    //maxHeight: 300,
    height: '100%'
    // marginTop: 74,
    //marginBottom: 20,
    
  },
  imageContainerMobile: {
    flex:1,
    textAlign: 'center',
    //maxHeight: 300,
    height: '180px'
    // marginTop: 74,
    //marginBottom: 20,
    
  },
  mainImage: {
    // width: "15vh",
    height: "20vh",
  },
  textCenter: {
    textAlign: 'center',
  },
  subTitleContainer: {
    maxWidth: 560,
    margin: '0 auto',
    paddingLeft: 40,
    paddingRight: 40,
  },
  buttonContainer: {
    textAlign: 'center',
    flex: 0.5,
    // display: 'flex',
    // justifyContent: 'center',
    //width: '300px'
  },
  addStyle: {
    height: '100%',
    display: 'flex',
    margin: '0 auto',
    fontSize: 20,
    width: '30%'
  },
  addStyleMobile: {
    height: '100%',
    display: 'flex',
    margin: '0 auto',
    //fontSize: 20,
    width: '40%'
  },
  textStyle: {
    //flex: 1,
    fontWeight: 'bold',
    fontSize: 30,
    margin: "auto",
    textDecoration: 'underline'
    //textAlign: "center",
    //marginBottom: 75,
  },
  textStyle2: {
    flex: 1,
    fontSize: 30,
    margin: "auto",
    textAlign: "center",
    //marginBottom: 75,
  },
  textStyleMobile: {
    fontWeight: 'bold',
    fontSize: 20,
    textDecoration: 'underline'
    //marginBottom: 25,
    //textAlign: "center"
  },
  iconStyle: {
      margin: "auto",
      width: 120,
      //marginTop: 75,
  },
  iconStyleMobile: {
    
    margin: "auto",
    //marginTop: 25,
    width: 80
  },
  spacer:{
    flex: 1
  },
  spacer2:{
    flex: 2
  },
  spacer3:{
    flex: 3
  },
  spacer4:{
    flex: 4
  },
  fitScreen:{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  backStyle: {
    flexShrink: 0,
    height: 40,
    width: 40,
    marginRight: 10
  },
  contentChildStyle:{

  },
  fitter: {
    flex: 15,
    display: 'flex'
  },
  bottomRow: {
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
});

const IntroScreen = ({
  selectedAvatar,
  selectedAvatarSrc,
  setSelectedAvatar,
  nextStep,
  uiTextToSpeechAutoplay,
  uiWizardStepChanged,
  uiAppAudioMuteStateChange,
  appMuted,
  storeCleanAudioPlayQueue
}) => {
  const {
    imageContainer,
    imageContainerMobile,
    mainImage,
    textCenter,
    subTitleContainer,
    buttonContainer,
    textStyle,
    textStyle2,
    textStyleMobile,
    iconStyle,
    iconStyleMobile,
    addStyle,
    spacer,
    spacer2,
    spacer3,
    spacer4,
    fitScreen,
    addStyleMobile,
    backStyle,
    contentChildStyle,
    fitter,
    bottomRow
  } = useStyles();

  const matches = useMediaQuery('(max-width:600px)');

  

  const handleNextStep = () => {
    uiWizardStepChanged();
    nextStep(2);
  };

  
  
  return (
    <div className={fitScreen}>
      <div className={spacer}></div>
      <div className={matches?imageContainerMobile:imageContainer}>
        <img className={mainImage} src={selectedAvatarSrc} alt="avatar" />
      </div>
      {/* <div className={spacer}></div> */}
      <div className={textCenter}>
        <Typography variant="h3">
          {'Vorstellungsvideo'}
        </Typography>
      </div>
      <div className={`${textCenter} ${subTitleContainer}`}>
        {/* <Typography variant="h5">
          {'Willst du, dass ich dir alles vorlese? Drücke auf das Symbol, um den Ton ein- oder auszuschalten'}
        </Typography> */}
      </div>
      <div className={spacer2}></div>
      {/* <div className={matches?iconStyleMobile:iconStyle} onClick={()=>{storeCleanAudioPlayQueueAction();uiAppAudioMuteStateChange();}}>
      {appMuted?(<Muted width={matches ? 80 : 120} height={matches ? 80 : 120}  />):(<Mute width={matches ? 80 : 120} height={matches ? 80 : 120}/>)}
      </div> */}
      <div className={fitter}>
      <ReactPlayer
        //ref='player'
        onEnded={handleNextStep}
        className={contentChildStyle}
        url="https://www.youtube.com/watch?v=wn_63ugchsc"
        width="100%"
        height="100%"
        onError={()=>console.log('ERRRRRR')}
        controls={true} />
        </div>
      {/* <Typography className={`${textStyle} ${matches ? textStyleMobile : ''}`}>
        {appMuted
          ? textContent.components.headerMuteButton.muted
          : textContent.components.headerMuteButton.mute}
      </Typography> */}
      <div className={spacer4}></div>
      <div className={bottomRow} onClick={handleNextStep}>
      <img src={backButton}  className={backStyle}/>
      <div>
      <Typography  className={`${textStyle} ${matches ? textStyleMobile : ''}`}>
        Intro Überspringen
      </Typography>
      </div>
      </div>
      {/* <div className={buttonContainer}>
        <SuccessButton
          additionalStyle={matches?addStyleMobile:addStyle}
          buttonText={
            textContent.wizardScreens.selectAssistantWizardScreen
              .successButtonText
          }
          onClick={handleNextStep}
        />
      </div> */}

      <div className={spacer3}></div>
      </div>
  );
};
const mapStateToProps = (state) => {
    
    return {
    };
  };
const mapDispatchToProps = {
    uiAppAudioMuteStateChange: uiAppAudioMuteStateChangeAction,
  uiTextToSpeechAutoplay: uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChanged: uiWizardStepChangedAction,
  storeCleanAudioPlayQueue: storeCleanAudioPlayQueueAction
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroScreen);
