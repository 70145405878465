import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#353945',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#353945',
    },
  },
});

const LinkButton = ({ additionalStyle, linkTo, buttonText }) => {
  const { button } = useStyles();
  return (
    <Button
      className={`${button} ${additionalStyle}`}
      variant="contained"
      component={RouterLink}
      to={linkTo}
    >
      <Typography variant="button">{buttonText}</Typography>
    </Button>
  );
};

export default LinkButton;
