import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import importAvatarImageDynamically from '../../../helpers/importAvatarDynamically';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#f2f2f2',
    padding: '20px 64px',
  },
  containerMobile: {
    backgroundColor: '#f2f2f2',
    padding: '5px 34px',
  },
  multipleButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  horizontalRule: {
    height: 6,
    width: '100%',
    border: 'none',
    marginTop: 7,
    marginBottom: 16,
    backgroundColor: '#D6D6D8',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  contentContainerMobile: {
    //flexWrap: 'wrap',
    justifyContent: 'start',
    marginBottom: 15
  },
  avatarContainer: {
    marginLeft: -36,
  },
  avatarContainerMobile: {
    //width: '100%',
    marginLeft: '-6%',
    textAlign: 'center',
  },
  avatarStyle: {
    width: 123,
    height: 143,
  },
  avatarStyleMobile: {
    // width: 123,
    height: 70,
  },
  textContent: {
    position: 'relative',
    //width: 'calc(100% - 150px)',
    marginLeft: 25,
    // maxWidth: 280,
    minWidth: 152,
    padding: 20,
    borderRadius: 5,
    backgroundColor: '#ffffff',
  },
  textContentMobile: {
    position: 'relative',
    //width: 'calc(100% - 150px)',
    marginLeft: 25,
    //maxWidth: 200,
    //minWidth: 152,
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#ffffff',
  },
  textStyle: {
    fontSize: 24,
  },
  textStyleMobile: {
    fontSize: 22,
  },
  textContentArrow: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '-20px',
    transform: 'translate(0, -50%)',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '16px 20px 16px 0',
    borderColor: 'transparent #ffffff transparent transparent',
  },
});

const Header = ({ avatar, headerText, children }) => {
  const [avatarImageSrc, setAvatarImageSrc] = useState();

  useEffect(() => {
    importAvatarImageDynamically(avatar)
      .then((r) => setAvatarImageSrc(r.default))
      .catch((e) => console.warn(e));
  }, [avatar]);

  const {
    container,
    multipleButtonContainer,
    buttonContainer,
    horizontalRule,
    contentContainer,
    contentContainerMobile,
    avatarContainer,
    avatarContainerMobile,
    avatarStyle,
    textContent,
    textStyle,
    textStyleMobile,
    textContentArrow,
    avatarStyleMobile,
    textContentMobile,
    containerMobile
  } = useStyles();

  const matches = useMediaQuery('(max-width:690px)');

  return (
    <div className={matches?containerMobile:container}>
      {children && (
        <div
          className={`${
            children.length ? multipleButtonContainer : buttonContainer
          }`}
        >
          {children}
        </div>
      )}
      <hr className={horizontalRule} />
      <div
        className={`${contentContainer} ${
          matches ? contentContainerMobile : ''
        }`}
      >
        <div
          className={`${avatarContainer} ${
            matches ? avatarContainerMobile : ''
          }`}
        >
          <img className={matches?avatarStyleMobile:avatarStyle} src={avatarImageSrc} alt="avatar" />
        </div>
        <div className={matches?textContentMobile:textContent}>
          <div className={textContentArrow} />
          <Typography
            className={`${textStyle} ${matches ? textStyleMobile : ''}`}
          >
            {headerText}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    userState: { avatar },
  } = state;
  return {
    avatar,
  };
};

export default connect(mapStateToProps, null)(Header);
