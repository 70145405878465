import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Sound from 'react-sound';
import { useHistory } from 'react-router-dom';
import {
  uiAudioPlayFinishedAction,
  uiCleanAudioPlayQueueRequestAction,
} from '../../../store/actions/textToSpeachActions';
import { detect } from 'detect-browser';
const browser = detect();

const AudioAutoplay = ({
  autoplayAudioURLs,
  appMuted,
  uiAudioPlayFinished,
  uiCleanAudioPlayQueueRequest,
}) => {
  const [playing, setPlaying] = useState('PLAYING');
  const [url, setUrl] = useState('');
  const history = useHistory();

  useEffect(() => {
    history.listen(() => uiCleanAudioPlayQueueRequest());
  }, [history, uiCleanAudioPlayQueueRequest]);

  useEffect(() => {
    if (!autoplayAudioURLs && !autoplayAudioURLs) {
      setPlaying('STOPPED');
      return;
    }
    setUrl(autoplayAudioURLs[0]);
    setPlaying('PLAYING');
  }, [autoplayAudioURLs]);

  const handlePlayingState = () => {
    setPlaying('STOPPED');
    uiAudioPlayFinished();
  };
  const isNotApple = (browser && browser.name !== 'safari' && browser.name !== 'ios');
  return !appMuted ? (
    <Sound
      autoload={isNotApple}
      url={url}
      playStatus={playing}
      onFinishedPlaying={handlePlayingState}
    />
  ) : null;
};

const mapStateToProps = (state) => {
  const {
    userState: { appMuted },
  } = state;

  const autoplayAudioURLs = state.textToSpeechState.autoplayAudioURLs.map(
    (e) => e.url
  );

  return {
    appMuted,
    autoplayAudioURLs,
  };
};

const mapDispatchToProps = {
  uiAudioPlayFinished: uiAudioPlayFinishedAction,
  uiCleanAudioPlayQueueRequest: uiCleanAudioPlayQueueRequestAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioAutoplay);
