import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import nunito from 'typeface-nunito';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito',
    h1: {fontWeight: 'bold',},
    h2: {fontWeight: 'bold',},
    h3: {fontWeight: 'bold',},
    h4: {fontWeight: 'normal',},
    h5: {fontWeight: 'normal',},
    h6: {fontWeight: 'normal',},
    subtitle1: {fontWeight: 'normal',},
    subtitle2: {fontWeight: 'normal',},
    body1: {fontWeight: 'normal',},
    body2: {fontWeight: 'normal',},
    button: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    caption: {fontWeight: 'normal',},
    overline: {fontWeight: 'normal',},
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [nunito],
      },
    },
  },
});

const rTheme = responsiveFontSizes(theme);

export default rTheme;
