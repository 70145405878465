import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  SuccessButtonLink,
  FailedButton,
  ContentButton,
} from '../../simpleComponents';

const useStyles = makeStyles({
  searchTermContainer: {
    border: '2px solid #E0E0E0',
    marginTop: 64,
    textAlign: 'center',
  },
  searchTermContainerMobile: {
    border: '2px solid #E0E0E0',
    //marginTop: 34,
    textAlign: 'center',
  },
  searchTermText: {
    fontSize: 20,
    color: '#000000',
  },
  searchTermTextMobile: {
    fontSize: 18,
    color: '#000000',
  },
  buttonContainer: {
    marginTop: 15,
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const AdditionalSearchConfirmation = ({
  newSearchTerm,
  successButtonOnClick,
  successButtonLink,
  successButtonText,
  failedButtonOnClick,
  failedButtonText,
  addKeywordButtonOnClick,
  addKeywordButtonText,
  addKeywordButtonIcon,
}) => {
  const { searchTermContainer, searchTermText, buttonContainer,
     searchTermContainerMobile, searchTermTextMobile } = useStyles();
  const matches = useMediaQuery('(max-width:690px)');
  return (
    <>
      <div className={matches?searchTermContainerMobile:searchTermContainer}>
        <Typography className={matches?searchTermTextMobile:searchTermText}>{newSearchTerm}</Typography>
      </div>
      <div className={buttonContainer}>
        <FailedButton
          onClick={failedButtonOnClick}
          buttonText={failedButtonText}
        />
        <SuccessButtonLink
          linkTo={successButtonLink}
          onClick={successButtonOnClick}
          buttonText={successButtonText}
        />
      </div>
      <ContentButton
        onClick={addKeywordButtonOnClick}
        buttonText={addKeywordButtonText}
        startIcon={addKeywordButtonIcon}
      />
    </>
  );
};

export default AdditionalSearchConfirmation;
