import {
  STORE_SEARCH_TERM,
  STORE_TEMP_SEARCH_TERM,
  STORE_SEARCH_SUGGESTION_RESULT,
  STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE,
  STORE_TEXT_SEARCH_RESULT,
  STORE_VIDEO_SEARCH_RESULT,
  STORE_PHOTO_SEARCH_RESULT,
  STORE_CLEAR_SEARCH_SUGGESTIONS,
  STORE_IMG_TAGS_TERM,
  STORE_SET_SLIDER_INDEX,
  UI_REQEST_RANDOM_WORDS
} from '../actions/actionTypes';

const searchInitState = {
  searchTerm: '',
  tempSearchTerm: '',
  searchSuggestions: null,
  currentImgTags: [],
  additionalKeywordRequested: false,
  textSearchResult: [],
  videoSearchResult: [],
  photoSearchResult: [],
  randomSearchResult: [],
  videoIndex: 0,
  photoIndex: 0,
  textIndex: 0
};

const searchReducer = (searchState = searchInitState, action) => {
  switch (action.type) {
    case UI_REQEST_RANDOM_WORDS: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        randomSearchResult: payload,
      };
    }
    case STORE_SET_SLIDER_INDEX: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        [`${payload.type}Index`]: payload.value,
      };
    }
    case STORE_SEARCH_TERM: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        searchTerm: payload,
      };
    }
    case STORE_IMG_TAGS_TERM: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        currentImgTags: payload,
      };
    }
    case STORE_TEMP_SEARCH_TERM: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        tempSearchTerm: payload,
      };
    }
    case STORE_SEARCH_SUGGESTION_RESULT: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        searchSuggestions: payload,
      };
    }
    case STORE_ADDITIONAL_SEARCH_KEYWORD_STATE_CHANGE: {
      return {
        ...searchState,
        additionalKeywordRequested: !searchState.additionalKeywordRequested,
      };
    }
    case STORE_TEXT_SEARCH_RESULT: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        textSearchResult: payload,
      };
    }
    case STORE_VIDEO_SEARCH_RESULT: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        videoSearchResult: payload,
      };
    }
    case STORE_PHOTO_SEARCH_RESULT: {
      const {
        payload
      } = action;
      return {
        ...searchState,
        photoSearchResult: payload,
      };
    }
    case STORE_CLEAR_SEARCH_SUGGESTIONS: {
      return {
        ...searchState,
        searchSuggestions: null,
      };
    }
    default:
      return searchState;
  }
};

export default searchReducer;