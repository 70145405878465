import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import rootReducer from './reducers/rootReducer';
import initState from './initState';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userState', 'searchState'],
};
const pReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleWare = createSagaMiddleware();
const logger = createLogger();

const configureStore = () => {
  const middleWares = [sagaMiddleWare];
  if (process.env.NODE_ENV === 'development') {
    //middleWares.push(logger);
  }

  const composeEnhancers =
    (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    pReducer,
    initState,
    composeEnhancers(applyMiddleware(...middleWares))
  );

  sagaMiddleWare.run(rootSaga);

  return store;
};

export default configureStore;
