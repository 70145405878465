import { LOADING_STARTED, LOADING_FINISHED } from '../actions/actionTypes';

const loadingInitState = {
  loading: false,
};

const loadingReducer = (loadingState = loadingInitState, action) => {
  switch (action.type) {
    case LOADING_STARTED: {
      return {
        ...loadingState,
        loading: true,
      };
    }
    case LOADING_FINISHED: {
      return {
        ...loadingState,
        loading: false,
      };
    }
    default:
      return loadingState;
  }
};

export default loadingReducer;
