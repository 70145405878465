import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  Container,
  SuccessButton,
  Tile,
} from '../../components/simpleComponents';
import manAvatar from '../../assets/images/manAvatar.svg';
import monkeyAvatar from '../../assets/images/monkeyAvatar.svg';
import womanAvatar from '../../assets/images/womanAvatar.svg';
import birdAvatar from '../../assets/images/birdAvatar.svg';
import textContent from '../../common/textContent';
import {
  uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChangedAction,
} from '../../store/actions/textToSpeachActions';
import Div100vh from 'react-div-100vh';

const useStyles = makeStyles({
  mainImageContainer: {
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  mainImage: {
    // width: "15vh",
    height: "20vh",
  },
  mainImageMobile: {
    // width: "15vh",
    height: "20vh",
  },
  textCenter: {
    textAlign: 'center',
  },
  subTitleContainer: {
    maxWidth: 560,
    margin: '0 auto',
    paddingLeft: 40,
    paddingRight: 40,
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  tileColumn: {
    display: 'flex',
    flexBasis: '100%',
    flex: 1,
    justifyContent: 'center',
  },
  tileColumnMobile: {
    //flexWrap: 'wrap',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  fitScreen:{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  spacer:{
    flex: 1
  },
  spacer4:{
    flex: 4
  },
});

const SelectAssistantWizardScreen = ({
  selectedAvatar,
  selectedAvatarSrc,
  setSelectedAvatar,
  nextStep,
  uiTextToSpeechAutoplay,
  uiWizardStepChanged,
}) => {
  const {
    mainImageContainer,
    mainImage,
    textCenter,
    subTitleContainer,
    tileContainer,
    tileColumn,
    tileColumnMobile,
    buttonContainer,
    fitScreen,
    mainImageMobile,
    spacer,
    spacer4
  } = useStyles();

  const matches = useMediaQuery('(max-width:600px)');

  const handleSelectAvatar = (event) => {
    const newAvatar = event.currentTarget.getAttribute('name');
    setSelectedAvatar(newAvatar);
  };

  const handleNextStep = () => {
    uiWizardStepChanged();
    nextStep(1);
  };

  useEffect(() => {
    uiTextToSpeechAutoplay([
      textContent.wizardScreens.selectAssistantWizardScreen.title,
      textContent.wizardScreens.selectAssistantWizardScreen.subtitle]
    );
  
  }, [uiTextToSpeechAutoplay]);

  return (
    
      <div className={fitScreen}>
      <div className={mainImageContainer}>
        <img className={matches?mainImageMobile:mainImage} src={selectedAvatarSrc} alt="avatar" />
      </div>
      <div className={textCenter}>
        <Typography variant="h3">
          {textContent.wizardScreens.selectAssistantWizardScreen.title}
        </Typography>
      </div>
      <div className={`${textCenter} ${subTitleContainer}`}>
        <Typography variant="h5">
          {textContent.wizardScreens.selectAssistantWizardScreen.subtitle}
        </Typography>
      </div>
      <div className={spacer}></div>
      <div className={tileContainer}>
        <div className={`${tileColumn} ${matches ? tileColumnMobile : ''}`}>
          <Tile
            image={manAvatar}
            imageAlt="tobi avatar"
            name="manAvatar"
            selected={selectedAvatar}
            onClick={handleSelectAvatar}
          />
          <Tile
            image={womanAvatar}
            imageAlt="woman avatar"
            name="womanAvatar"
            selected={selectedAvatar}
            onClick={handleSelectAvatar}
          />
        </div>
        <div className={`${tileColumn} ${matches ? tileColumnMobile : ''}`}>
          <Tile
            image={birdAvatar}
            imageAlt="bird avatar"
            name="birdAvatar"
            selected={selectedAvatar}
            onClick={handleSelectAvatar}
          />
          <Tile
            image={monkeyAvatar}
            imageAlt="monkey avatar"
            name="monkeyAvatar"
            selected={selectedAvatar}
            onClick={handleSelectAvatar}
          />
        </div>
      </div>
      <div className={spacer4}></div>
      <div className={buttonContainer}>
        <SuccessButton
          buttonText={
            textContent.wizardScreens.selectAssistantWizardScreen
              .successButtonText
          }
          onClick={handleNextStep}
        />
      </div>
      <div className={spacer4}></div>
      </div>

  );
};

const mapDispatchToProps = {
  uiTextToSpeechAutoplay: uiTextToSpeechAutoplayRequestAction,
  uiWizardStepChanged: uiWizardStepChangedAction,
};

export default connect(null, mapDispatchToProps)(SelectAssistantWizardScreen);
