import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AppRouter from './router/router';
import { LoadingIndicator } from './components/simpleComponents';
import { AudioAutoplay } from './components/advancedComponents';
import { uiAppLoadedAction } from './store/actions/appActions';
import SimpleDialog from './components/advancedComponents/helpDialog';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  screenStyle: {
    position: "relative",
    height: "100%",
    width: "100%"
  }
});

const App = ({ uiAppLoaded }) => {
  useEffect(() => {
    uiAppLoaded();
  }, [uiAppLoaded]);
  const {screenStyle} = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = (value) => {
    setOpen(false);
    //setSelectedValue(value);
  };
  return (
    <div className={screenStyle}>
      <AudioAutoplay />
      <SimpleDialog />
      <AppRouter />
      <LoadingIndicator />
    </div>
  );
};

const mapDispatchToProps = {
  uiAppLoaded: uiAppLoadedAction,
};

export default connect(null, mapDispatchToProps)(App);
