import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRef } from 'react';

const useStyles = makeStyles({
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-around',
    textAlign: 'center',
    cursor: 'pointer',
  },
  cardSize: {
    width: 178,
    height: 178,
    margin: 30,
  },
  cardSizeMobile: {
    width: 85,
    height: 50,
    margin: 10,
  },
  cardStyleColorsHover: {
    '&:hover': {
      background: '#353945',
    },
  },
  cardContentStyleMobile: {
    padding: '4px 4px 4px 4px !important'
  },
  cardStyleInverseColors: {
    backgroundColor: '#353945',
    
    cursor: 'pointer',
  },
  cardStyleInverseColorsHover: {
    '&:hover': {
      background: '#ffffff',
    },
  },
  selectedStyle: {
    backgroundColor: '#353945',
  },
  selectedStyleInverseColors: {
    backgroundColor: '#ffffff',
  },
  text: {
    color: '#353945',
  },
  textSize: {
    fontSize: '22px',
    fontWeight: 'bold'
  },
  textSizeMobile: {
    fontSize: '17px',
    fontWeight: 'bold'
  },
  textInverseColors: {
    color: '#ffffff',
  },
  textSelected: {
    color: '#ffffff',
  },
  textSelectedInverseColors: {
    color: '#353945',
  },
});

const TileSVG = ({
  SVG,
  imageAlt,
  name,
  selected,
  onClick,
  label,
  additionalCardStyle,
  inverseColors,
  
}) => {
  const [hovered, setHovered] = useState(false);
  const [svgFillColor, setSvgFillColor] = useState('#353945');
  const matches = useMediaQuery('(max-width:690px)');
  const itemSelected = selected === name;

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseOver = () => {
    setHovered(true);
  }

  const memoizedGetFillColorFunction = useCallback(() => {
    if ((hovered || itemSelected) && !inverseColors) {
      return '#ffffff';
    }
    if ((hovered || itemSelected) && inverseColors) {
      return '#353945';
    }
    if ((!hovered || !itemSelected) && !inverseColors) {
      return '#353945';
    }
    if ((!hovered || !itemSelected) && inverseColors) {
      return '#ffffff';
    }
    return '#000000';
  }, [hovered, itemSelected, inverseColors]);

  useEffect(() => {
    const fillColor = memoizedGetFillColorFunction();
    setSvgFillColor(fillColor);
  }, [itemSelected, hovered, memoizedGetFillColorFunction]);

  
  const {
    cardStyle,
    selectedStyle,
    selectedStyleInverseColors,
    cardStyleInverseColors,
    text,
    textInverseColors,
    textSelected,
    textSelectedInverseColors,
    cardStyleColorsHover,
    cardSize,
    cardSizeMobile,
    textSize,
  textSizeMobile,
  cardContentStyleMobile,
  cardStyleInverseColorsHover
  } = useStyles();

  return (
    <Card
      className={`${inverseColors ? cardStyleInverseColors : ''} ${cardStyle} 
      ${!matches? (inverseColors? cardStyleInverseColorsHover:cardStyleColorsHover):''}
      ${
        matches?cardSizeMobile:cardSize
      } ${
        itemSelected && !inverseColors ? selectedStyle : ''
      } ${
        itemSelected && inverseColors ? selectedStyleInverseColors : ''
      } ${additionalCardStyle}`}
      name={name}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <CardContent className={matches?cardContentStyleMobile: ''}>
        <SVG fill={svgFillColor} width={matches?16:36} height={matches?16:36} alt={imageAlt} />
        <Typography
          className={`${inverseColors ? textInverseColors : text} ${
            matches?textSizeMobile:textSize
          } ${
            (hovered || itemSelected) && !inverseColors ? textSelected : ''
          } ${
            (hovered || itemSelected) && inverseColors
              ? textSelectedInverseColors
              : ''
          }`}
        >
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TileSVG;
