import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  modalContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    padding: '20px 0 20px 0',
  },
});

const ContentModal = ({ children }) => {
  const { modalContainer } = useStyles();
  return <div className={modalContainer}>{children}</div>;
};

export default ContentModal;
