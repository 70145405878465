import { SHOW_MODAL, HIDE_MODAL } from '../actions/actionTypes';

const modalInitState = {
  show: false,
};

const modalReducer = (modalState = modalInitState, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {modalState,
        show: true,
      };
    }
    case HIDE_MODAL: {
      return {
        ...modalState,
        show: false,
      };
    }
    default:
      return modalState;
  }
};

export default modalReducer;
