import Resizer from 'react-image-file-resizer';

const imgResizer = (img) => new Promise(res=>Resizer.imageFileResizer(
    img,
    500,
    500,
    'JPEG',
    100,
    0,
    blob => {
        res(blob)
    },
    'blob'
  ));

export default imgResizer;