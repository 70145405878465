import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  InputTextField,
  ContentLinkButton,
  ContentButton,
  // ContentModal,
} from '../../simpleComponents';
// import AdditionalSearchConfirmation from '../additionalSearchConfirmation';
import {ReactComponent as searchIcon} from '../../../assets/icons/search.svg';
import textContent from '../../../common/textContent';
// import plus from '../../../assets/icons/plus.svg';
import {
  uiSearchSuggestionRequestAction,
  uiAdditionalSearchKeywordStateChangeRequestAction,
} from '../../../store/actions/searchActions';
import { uiTextToSpeechAutoplayRequestAction } from '../../../store/actions/textToSpeachActions';
import { useHistory } from 'react-router-dom';
import PageMargin from '../../simpleComponents/pageMargin';

const useStyles = makeStyles({
  searchTermContainer: {
    border: '2px solid #E0E0E0',
    marginTop: 44,
    textAlign: 'center',
  },
  searchTermText: {
    fontSize: 20,
  },
  container:{
    // marginLeft: 40,
    // marginRight: 40
  },
  conatinerMobile: {
    // marginLeft: 20,
    // marginRight: 20
  }
});

const TextSearch = ({
  searchTerm,
  additionalKeywordRequested,
  uiSearchSuggestionRequest,
  uiAdditionalSearchKeywordStateChangeRequest,
  // uiTextToSpeechAutoplayRequest
}) => {
  const matches = useMediaQuery('(max-width:690px)');
  const { searchTermContainer, searchTermText, conatinerMobile, container } = useStyles();
  const [fieldValue, setFieldValue] = useState('');
  const [newSearchTerm] = useState(searchTerm);
  const history = useHistory();
  // const [showConfirmation, setShowConfirmation] = useState(false);

  const handleLinkButtonClick = (e) => {
    e.preventDefault();
    if (!fieldValue) {
      return;
    }
    uiSearchSuggestionRequest(fieldValue);
    history.push('/search-suggestion');
  };

  // const handleAddKeywordButtonClick = () => {
  //   if (!fieldValue) {
  //     return;
  //   }
  //   setNewSearchTerm(`${newSearchTerm} ${fieldValue}`);
  //   //setShowConfirmation(true);
  // };

  // const handleAdditionalSearchConfirmationFailed = () => {
  //   setNewSearchTerm(searchTerm);
  //   setFieldValue('');
  //   setShowConfirmation(false);
  // };

  const handleAdditionalSearchConfirmationSuccess = (e) => {
    e.preventDefault();
    // handleAddKeywordButtonClick();
    if (!fieldValue) {
      return;
    }
    uiSearchSuggestionRequest(`${newSearchTerm} ${fieldValue}`);
    uiAdditionalSearchKeywordStateChangeRequest();
    history.push('/search-suggestion');
  };

  // const handleAdditionalSearchConfirmationAddKeyword = () => {
  //   setShowConfirmation(false);
  //   setFieldValue('');
  // };

  // useEffect(() => {
  //   if (showConfirmation) {
  //     uiTextToSpeechAutoplayRequest(
  //       textContent.components.textSearch.confirmationContentButton);
  //   }
  // }, [showConfirmation]);

  return (
    <PageMargin>
    <div className={matches?conatinerMobile:container}>
      {additionalKeywordRequested ? (
        <div className={searchTermContainer}>
          <Typography className={searchTermText}>{newSearchTerm}</Typography>
        </div>
      ) : null}
      <form onSubmit={additionalKeywordRequested ? handleAdditionalSearchConfirmationSuccess : handleLinkButtonClick}>
        <InputTextField
          placeholder={
            additionalKeywordRequested
              ? textContent.components.textSearch
                .inputPlaceholderAdditionalKeyword
              : textContent.components.textSearch.inputPlaceholder
          }
          value={fieldValue}
          onChange={setFieldValue}
        />
        {additionalKeywordRequested ? (
          <ContentButton
            type="submit"
            buttonText={textContent.components.textSearch.buttonText}
            //onClick={handleAdditionalSearchConfirmationSuccess}
            startIcon={searchIcon}
          //linkTo="/search-suggestion"
          />
        ) : (
            <ContentButton
              type="submit"
              //linkTo="/search-suggestion"
              startIcon={searchIcon}
              buttonText={textContent.components.textSearch.buttonText}
            //onClick={handleLinkButtonClick}
            />
          )}
      </form>
      {/* {showConfirmation ? (
        <ContentModal>
          <AdditionalSearchConfirmation
            newSearchTerm={newSearchTerm}
            successButtonLink="/search-suggestion"
            successButtonOnClick={handleAdditionalSearchConfirmationSuccess}
            successButtonText={
              textContent.components.textSearch.confirmationSuccessButton
            }
            failedButtonOnClick={handleAdditionalSearchConfirmationFailed}
            failedButtonText={
              textContent.components.textSearch.confirmationFailedButton
            }
            addKeywordButtonOnClick={
              handleAdditionalSearchConfirmationAddKeyword
            }
            addKeywordButtonText={
              textContent.components.textSearch.confirmationContentButton
            }
            addKeywordButtonIcon={plus}
          />
        </ContentModal>
      ) : null} */}
    </div>
    </PageMargin>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  uiSearchSuggestionRequest: uiSearchSuggestionRequestAction,
  uiAdditionalSearchKeywordStateChangeRequest: uiAdditionalSearchKeywordStateChangeRequestAction,
  uiTextToSpeechAutoplayRequest: uiTextToSpeechAutoplayRequestAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextSearch);
